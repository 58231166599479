import * as React from "react";

type OptionsProps = {
	header: string;
};

/**
 * Option component to render form option subcomponents with headers.
 * Renders the child elements to a wrapper div.
 *
 * @param props Contains the option header string
 */
export const OptionComponent: React.FC<OptionsProps> = (props) => {

	return (
		<div className="options--set">
			<div className="options--set--title">
				{props.header}
			</div>
			<div className="options--set--wrap">
				{props.children}
			</div>
		</div>
	);
};
