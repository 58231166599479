import * as React from "react";

type OptInputLabel = {
	name: string;
	text: string;
};

/**
 * Subcomponent for form Options to create radio input for non-numeric values.
 *
 * @param props Contains callback function for onChange action, data object and
 * the initial value key.
 */
export const OptInputLabel: React.FC<OptInputLabel> = (props) => {

	return (
		<label htmlFor={props.name}>
			{
				props.text
			}
		</label>
	);
};
