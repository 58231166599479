import "../scss/reset.scss";
import "../scss/styles.scss";
import "material-icons";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./App";

const rootElement = document.getElementById("root");

ReactDOM.render(<App/>, rootElement);
