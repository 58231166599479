import * as React from "react";
import { Page } from "../Constants";
import { GeneralDataForm } from "../GeneralDataForm";
import { ConsistencyCalculation } from "./ConsistencyCalculation";
import { ConsistencyRequirements } from "./ConsistencyRequirements";
import { Summary } from "./Summary";

export enum Steps {
	Calculation = "calculation",
	Requirements = "requirements",
	Summary = "summary",
}

/**
 * Renders the consistency page handling the overall layout logic with step visualization.
 * TODO: step handling when the pages are actually done for it.
 */
export const ConsistencyPage: React.FC<{}> = () => {

	const [currentStep, setCurrentStep] = React.useState(Steps.Calculation);

	const steps = [
		{
			key: "calculation",
			title: "Consistency Calculation",
		},
		{
			key: "requirements",
			title: "Pipe Requirements",
		},
		{
			key: "summary",
			title: "Summary",
		},
	];

	const [displayForm, setDisplayState] = React.useState(true);

	return (
		<div className="consistencypage">
			<div className="consistencypage--top">
				<div className="backbutton">
					<div className="backbutton--icon" onClick={e => {
						window.location.pathname = Page.LandingPage;
					}}/>
				</div>
				<div className="consistencypage--top--steps">
					{
						steps.map(item => {
							let enumKey = item.key as Steps;
							return (
								<div className={"consistencypage--top--steps--item"
									+ (currentStep === item.key ? " active" : "")}
									onClick={e => setCurrentStep(enumKey)}
									key={item.key}>
									{item.title}
								</div>
							);
						})
					}
				</div>
				<div className="consistencypage--top--mobile">
					{
						steps.map(item => {
							let enumKey = item.key as Steps;
							return (
								<div className={"consistencypage--top--mobile--item"
									+ (currentStep === item.key ? " active" : "")}
									onClick={e => setCurrentStep(enumKey)}
									key={item.key}>
								</div>
							);
						})
					}
				</div>
				<div className="consistencypage--top--right">
					<div className="consistencypage--top--right--header">
						{
							<span className="material-icons-outlined" onClick={e => setDisplayState(false)}>info</span>
						}
					</div>
				</div>
			</div>
			{
				currentStep === Steps.Calculation &&
				<ConsistencyCalculation nextStep={setCurrentStep}/>
			}
			{
				currentStep === Steps.Requirements &&
				<ConsistencyRequirements nextStep={setCurrentStep}/>
			}
			{
				currentStep === Steps.Summary &&
				<Summary/>
			}
			{
				!displayForm &&
				<div className="consistencypage--generalform">
					<GeneralDataForm appCallback={setDisplayState} callbackParameter={true}/>
				</div>
			}
		</div>
	);
};
