import * as React from "react";

type PipeDataProps = {
	title: string;
	value: string;
	unit: string;
};

/**
 * Sets the pipe data to a row layout.
 */
export const PipeDataRow: React.FC<PipeDataProps> = ({title, value, unit}) => {

	return (
		<div className="row">
			<div className="row--header">
				{
					title + ": "
				}
			</div>
			<div className="row--text">
				{
					value + " " + unit
				}
			</div>
		</div>
	);
};
