import * as React from "react";

/**
 * Help page, structure and content from the original.
 */
export const HelpPage: React.FC<{}> = () => {

	return (
		<div className="helppage">
			<div className="helppage-wrap">
				<h1>
					What is Measurement Advisor?
				</h1>
				<div className="helppage-wrap-spacer"></div>
				<h2>
					Consistency tool
				</h2>
				<ul>
					<li>
						<p>
							The consistency tool helps you select the correct device for
							situations with different parameters. By giving some parameters,
							the tool will draw you a graph that visualizes the range of operation
							and give you information about installation.
						</p>
					</li>
					<li>
						<p>
							This tool has three steps. You can proceed to the next step by clicking
							the 'Next' button in the lower right-hand side or by clicking the links
							in the navigation above.
						</p>
						<ul>
							<li>
								<b>Consistency Calculation</b> - At first you will give the necessary parameters
								for the graph. The green area of the graph shows the recommended range of
								operation for selected pulp and sensor type. The blue area shows the target
								flow and consistency.
							</li>
							<li>
								<b>Pipe requirements</b> - At the second step you will select the mounting orientation.
								You can select one or several mounting positions; just click the box on the upper right
								corner of each position.
							</li>
							<li>
								<b>Summary.</b> - The final step is the summary. This will show your input, the graph,
								and installation information. This page can be saved as a pdf file on your pc for
								further use.
							</li>
						</ul>
					</li>
				</ul>
				<div className="helppage-wrap-spacer"></div>
				<h2>
					Dilution calculator
				</h2>
				<ul>
					<li>
						Calculation of the pulp consistency dilution
					</li>
					<ul>
						<li>
							Quick and easy help for correct set up
						</li>
						<li>
							Consistency setpoint
						</li>
						<li>
							Pipe sizes
						</li>
						<li>
							Dilution water need
						</li>
						<li>
							Dilution valve capacity
						</li>
					</ul>
				</ul>
				<h2>
					Download materials
				</h2>
				<ul>
					<li>
						<p>
							Read more about Valmet Consistency solutions
						</p>
					</li>
					<ul>
						<li>
							<p>
								Get the latest material concerning Valmet measurement portfolio
							</p>
						</li>
					</ul>
				</ul>
				<h2>
					Unit converter
				</h2>
				<ul>
					<li>
						<p>
							Conversion of standard units
						</p>
					</li>
					<ul>
						<li>
							<p>
								A quick help to get the correct units
							</p>
						</li>
					</ul>
				</ul>
			</div>
		</div>
	);
};
