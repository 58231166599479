import * as React from "react";
// @ts-ignore No typings exist for this.
import ImageMapper from 'react-image-mapper';
import { Mounting, mountingOptions, VelocityUnit } from "../Constants";
import { useConsistencyState, useUpdateConsistency } from "./ConsistencyContext";
import { Steps } from "./ConsistencyPage";
import { MountingOption } from "./MountingOption";
import { PipeDataRow } from "./PipeDataRow";
import { PulpData } from "./PulpData";

let imgRef: HTMLDivElement | null = null;

type MappedCoordinates = {
	_id: string;
	name: keyof typeof Mounting;
	shape: string;
	coords: number[];
};

/**
 * Object for ImageMapper to map the coordinates.
 * Copied over from earlier implementation and
 * re-fit to new image dimensions.
 */
const coordinates: {
	name: string;
	areas: MappedCoordinates[];
} = {
	name: "pipeareas",
	areas: [
		{
			_id: "position_a",
			name: "A",
			shape: "rect",
			coords: [200, 139, 280, 219],
		},
		{
			_id: "position_b1",
			name: "B1",
			shape: "rect",
			coords: [410, 146, 490, 220],
		},
		{
			_id: "position_b2",
			name: "B2",
			shape: "rect",
			coords: [370, 70, 450, 145],
		},
		{
			_id: "position_c",
			name: "C",
			shape: "rect",
			coords: [780, 135, 860, 220],
		},
		{
			_id: "position_d",
			name: "D",
			shape: "rect",
			coords: [560, 60, 640, 140],
		},
	],
};

type RequirementsProps = {
	nextStep: React.Dispatch<React.SetStateAction<Steps>>;
};

/**
 * Component to handle the layout and display of the pipe calculation
 * values, the mounting positions and their selection.
 */
export const ConsistencyRequirements: React.FC<RequirementsProps> = ({nextStep}) => {

	const context = useConsistencyState();
	const updateConsistency = useUpdateConsistency();

	const pipeCalculations = PulpData.calculatePipeValues(context);

	const [imgWidth, setImgWidth] = React.useState(2338);
	const [imgHeight, setImgHeight] = React.useState(1018);

	const currentSelection = context.mounting;
	let initialText = mountingOptions[0].text;

	const currentOptions = context.mountingSelection;

	const [currentText, setText] = React.useState(initialText);
	const [displayValues, setDisplayValues] = React.useState(pipeCalculations[currentSelection]);

	const resizeHandler = () => {
		if (imgRef) {
			setImgWidth(imgRef.clientWidth);
			setImgHeight(imgRef.clientHeight);
		}
	};

	const updateMountingSelection = (key: string, checked: boolean) => {
		let value = key as keyof typeof Mounting;
		let selections = context.mountingSelection;
		selections[value].selected = checked;
		updateConsistency({...context, mountingSelection: selections });
	};

	window.onresize = resizeHandler;

	/**
	 * Click handler for the ImageMapper areas
	 * Potential additional parameters are index as number
	 * and the event object as third.
	 *
	 * @param area Object of area clicked.
	 */
	const clickHandler = (area: MappedCoordinates) => {
		updateConsistency({...context, mounting: area.name });
	};

	React.useEffect(() => {
		let text = currentText;
		mountingOptions.forEach(item => {
			if (item.key === currentSelection) {
				text = item.text;
			}
		});
		setText(text);
		setDisplayValues(pipeCalculations[currentSelection]);
	}, [currentSelection]);

	return (
		<div id="crequirements">
			<div className="wrapper">
				<div className="positionview">
					<div className="positionview--title">
						Pipe requirements
					</div>
					<div className="positionview--area"
						ref={e => { imgRef = e; setImgWidth(e?.clientWidth!); setImgHeight(e?.clientHeight!); }}>
						<ImageMapper src={"./assets/pipe_positions.png"} map={coordinates} onClick={clickHandler}
							width={imgWidth} height={imgHeight} strokeColor={"#FF6600"} />
					</div>
				</div>
				<div className="orientation">
					<div className="orientation--title">
						Mounting orientation
					</div>
					<div className="orientation--list">
						<select value={currentSelection}
							onChange={e => {
								let value = e.target.value as keyof typeof Mounting;
								updateConsistency({...context, mounting: value });
							}}>
							{
								mountingOptions.map(item => {
									return (
										<option key={item.key} value={item.key}>
											{item.name}
										</option>
									);
								})
							}
						</select>
					</div>
					<div className="orientation--text">
							{
								currentText
							}
					</div>
					<div className="orientation--title">
						Straight pipe requirements
					</div>
					<div className="orientation--requirements">
						<PipeDataRow title={"Upstream (L1)"}
							value={displayValues!.L1.toFixed(0)} unit={context.pipeUnit}/>
						<PipeDataRow title={"Downstream (L2)"}
							value={displayValues!.L2.toFixed(0)} unit={context.pipeUnit}/>
						<PipeDataRow title={"Max. velocity"}
							value={(context.velocityMax === "" ? 0 : context.velocityMax).toFixed(2)}
							unit={VelocityUnit[context.velocityUnit]}/>
						<PipeDataRow title={"Min. consistency"}
							value={(context.consistencyMin === "" ? 0 : context.consistencyMin).toFixed(2)}
							unit={"% Cs"}/>
					</div>
				</div>
				<div className="mounting">
					<div className="mounting--title">
						Pipe mounting options
					</div>
					<div className="mounting--subtitle">
						Choose up to five options for mounting.
					</div>
					<div className="mounting--flex">
						{
							Object.keys(pipeCalculations).map((key: keyof typeof Mounting) => {
								const opt = pipeCalculations[key];
								return (
									<MountingOption callback={updateMountingSelection} checked={currentOptions[key].selected}
										value={key} L1title={"Upstream (L1)"} L1={opt!.L1.toFixed(0)}
										L2title={"Downstream (L2)"} L2={opt!.L2.toFixed(0)}
										unit={context.pipeUnit} key={key}/>
								);
							})
						}
					</div>
					<div className="mounting--submit">
						<button onClick={e => nextStep(Steps.Summary)}>
							Next: Summary
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
