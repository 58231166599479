import * as React from "react";

type UnitSelectProps = {
	header: string;
	updateUnit: (e: React.ChangeEvent, key: string) => void;
	currentUnit: string;
	contextKey: string;
	valueKeys: {[key: string]: string};
};

export const UnitSelect: React.FC<UnitSelectProps> = ({
	header,
	updateUnit,
	currentUnit,
	contextKey,
	valueKeys,
}) => {

	return(
		<div className="unitwrap">
			<div className="unitwrap--header">
				{header}
			</div>
			<select onChange={e => updateUnit(e, contextKey)}
				value={currentUnit}>
				{
					Object.keys(valueKeys).map((key: keyof typeof valueKeys) => {
						const name = valueKeys[key];
						return (
							<option key={key} value={key}>
								{name}
							</option>
						);
					})
				}
			</select>
		</div>
	);
};
