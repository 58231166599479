import * as React from "react";
import { generaldefaults, GeneralData } from "./Constants";

type Dispatch = React.Dispatch<React.SetStateAction<GeneralData>>;

const GeneralState
	= React.createContext<GeneralData | undefined>(undefined);
const UpdateGeneral
	= React.createContext<Dispatch | undefined>(undefined);

/**
 * Context provider as a component. Renders it's children
 * inside it's context.Providers.
 */
export const GeneralProvider: React.FC<{}> = ({children}) => {
	const dataKey = "generalData";
	let initialValues = generaldefaults;
	// Load initial values from localStorage if available.
	if ('localStorage' in window && window['localStorage'] !== null) {
		const localStr = window.localStorage;
		try {
			const storedState = localStr.getItem(dataKey);
			if (storedState) {
				initialValues = JSON.parse(storedState);
			}
		} catch (e) {
			console.warn(e);
		}
	}
	const [generalState, updateGeneralState] = React.useState(initialValues);

	const dataUpdate = (state: GeneralData) => {
		// save localStorage
		if ('localStorage' in window && window['localStorage'] !== null) {
			const localStr = window.localStorage;
			try {
				localStr.setItem(dataKey, JSON.stringify(state));
			} catch (e) {
				console.warn(e);
			}
		}
		updateGeneralState(state);
	};
	return (
		<GeneralState.Provider value={generalState}>
			<UpdateGeneral.Provider value={dataUpdate}>
				{children}
			</UpdateGeneral.Provider>
		</GeneralState.Provider>
	);
};

/**
 * Function for loading the context state from a child component.
 * Outside the provider leads to an error.
 */
export const useGeneralState: () => GeneralData = () => {
	const context = React.useContext(GeneralState);
	if (context === undefined) {
		throw new Error('useConsistencyState must be used within provider');
	}
	return context;
};

/**
 * Function for loading the context state update function from a child component.
 * Outside the provider leads to an error.
 */
export const useUpdateGeneral: () => Dispatch = () => {
	const context = React.useContext(UpdateGeneral);
	if (context === undefined) {
		throw new Error('useUpdateConsistency must be used within provider');
	}
	return context;
};
