import * as React from "react";

type DocumentProps = {
	name: string;
};

export const DocumentItem: React.FC<DocumentProps> = ({ name }) => {

	return (
		<div className="doc" key={name}>
			<div className="item"
				onClick={e => {
					window.open("/assets/docs/" + name + ".pdf");
				}}>
				<div className="item--thumb"
					style= {{ backgroundImage: "url('/assets/thumbnails/" + name + ".jpg')" }}/>
				<div className="item--wrap">
					<div className="item--wrap--title" title={name}>
						{name}
					</div>
					<div className="item--wrap--bottom"></div>
				</div>
			</div>
		</div>
	);
};
