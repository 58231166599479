import { PulpNames, allSENSORS, PipeType,
	MATERIALS, Mounting, FlowUnit,
	VelocityUnit, PipeUnit, ConsistencyData,
} from "../Constants";

type Pulps = {
	[K in keyof typeof PulpNames]: {
		[N in keyof typeof allSENSORS]?: {
			path: [number, number][];
			a: number;
			b: number;
			c: number;
			d: number;
			materials?: (keyof typeof MATERIALS)[];
		}
	}
};

type PipeValues = {
	[K in keyof typeof Mounting]?: {
		L1: number;
		L2: number;
		zsimw: number;
		ssimw: number;
		k: number;
	}
};

/**
 * Class containing static functions and objects to handle
 * some "global" data for the calculations.
 */
export class PulpData {

	/**
	 * Object containing the materials and coordinate sets for given
	 * pulp / sensor combinations.
	 */
	private static ThePulp: Pulps = {
		SWU: {
			HL: {
				path: [
					[0.7, 4], [0.73, 5],
					[0.85, 9], [1, 11],
					[1.1, 12.6], [1.25, 14],
					[1.5, 16], [8, 16],
					[8, 7.8], [7, 6.4],
					[6, 5], [5, 4],
				],
				materials: ["aisip", "smop"],
				a: 0.0059568,
				b: -0.0384743,
				c: 0.771539,
				d: -1.38952,
			},
			LL: {
				path: [
					[0.45, 1.5], [0.42, 1.8],
					[0.5, 3], [0.62, 5],
					[0.7, 6], [3.5, 6],
					[5, 5], [5, 2.7],
					[3.8, 2], [2.5, 1.5],
				],
				a: -0.0124263,
				b: 0.806228,
				c: -1.94163,
				d: 1.83018,
			},
			UL: {
				path: [
					[0.04, 0.45], [0.08, 1.5],
					[0.13, 2.35], [0.2, 3],
					[2, 3], [2, 1.5],
					[0.8, 0.45],
				],
				a: 0.023221,
				b: 0.34048,
				c: -0.10636,
				d: 0.0814896,
			},
			RL: {
				path: [
					[0.4, 1.5], [0.39, 2],
					[0.48, 3], [0.58, 4],
					[0.7, 5], [0.85, 6],
					[3, 6], [4, 5.45],
					[4, 2.2], [3.6, 2],
					[3, 1.7], [2.75, 1.6],
					[2.4, 1.55], [2, 1.5],
				],
				a: -0.027537,
				b: 0.83127,
				c: -1.60744,
				d: 1.54538,
			},
			GL: {
				path: [
					[0.4, 1.5], [0.5, 3],
					[0.6, 5], [0.7, 6],
					[0.8, 7], [3, 7],
					[4, 5.5], [4, 2.3],
					[3, 1.7], [2.25, 1.5],
				],
				materials: ["aisip", "smop"],
				a: -0.051392,
				b: 1.13582,
				c: -3.48753,
				d: 3.46961,
			},
			JL: {
				path: [
					[0.4, 1.5], [0.5, 3],
					[0.55, 4], [0.65, 5],
					[0.75, 6], [3.5, 6],
					[5, 5], [5, 2.9],
					[3.55, 2], [3, 1.8],
					[2.2, 1.5],
				],
				materials: ["aisiu", "aisip", "smou", "smop"],
				a: -0.052014,
				b: 1.02424,
				c: -2.71703,
				d: 2.47163,
			},
		},
		SWB: {
			HL: {
				path: [
					[0.7, 4], [0.73, 5],
					[0.85, 9], [1, 11],
					[1.1, 12.6], [1.25, 14],
					[1.5, 16], [8, 16],
					[8, 7.8], [7, 6.4],
					[6, 5], [5, 4],
				],
				materials: ["aisip", "smop"],
				a: 0.007472,
				b: -0.102805,
				c: 1.02565,
				d: -2.197873,
			},
			LL: {
				path: [
					[0.45, 1.5], [0.42, 1.8],
					[0.5, 3], [0.62, 5],
					[0.7, 6], [3.5, 6],
					[5, 5], [5, 2.7],
					[3.8, 2], [2.5, 1.5],
				],
				a: 0.0931609,
				b: -0.450488,
				c: 1.9603,
				d: -1.69648,
			},
			GL: {
				path: [
					[0.4, 1.5], [0.5, 3],
					[0.6, 5], [0.7, 6],
					[0.8, 7], [3, 7],
					[4, 5.5], [4, 2.3],
					[3, 1.7], [2.25, 1.5],
				],
				materials: ["aisip", "smop"],
				a: -0.0154045,
				b: 0.6932257,
				c: -2.23275,
				d: 2.26609,
			},
			RL: {
				path: [
					[0.4, 1.5], [0.39, 2],
					[0.48, 3], [0.58, 4],
					[0.7, 5], [0.85, 6],
					[3, 6], [4, 5.45],
					[4, 2.2], [3.6, 2],
					[3, 1.7], [2.75, 1.6],
					[2.4, 1.55], [2, 1.5],
				],
				a: 0.0176536,
				b: 0.384489,
				c: -0.792611,
				d: 1.00416,
			},
			UL: {
				path: [
					[0.04, 0.45], [0.08, 1.5],
					[0.13, 2.35], [0.2, 3],
					[2, 3], [2, 1.5], [0.8, 0.45],
				],
				a: 0.0251579,
				b: 0.348803,
				c: -0.2287,
				d: 0.0747348,
			},
			JL: {
				path: [
					[0.4, 1.5], [0.5, 3],
					[0.55, 4], [0.65, 5],
					[0.75, 6], [3.5, 6],
					[5, 5], [5, 2.9],
					[3.55, 2], [3, 1.8],
					[2.2, 1.5],
				],
				materials: ["aisiu", "aisip", "smou", "smop"],
				a: -0.0294528,
				b: 0.744569,
				c: -1.86553,
				d: 1.6395,
			},
		},
		HWU: {
			LL: {
				path: [
					[0.42, 1.8], [0.5, 3],
					[0.62, 5], [4, 5],
					[4, 2.5], [2.4, 1.8],
				],
				a: 0.0130601,
				b: 0.253436,
				c: -0.255298,
				d: 0.112358,
			},
			UL: {
				path: [
					[0.05, 1], [0.08, 1.5],
					[0.13, 2.35], [0.2, 3],
					[2, 3], [2, 1.5],
					[1.25, 1],
				],
				a: -0.055844,
				b: 0.588667,
				c: -0.7806313,
				d: 0.3502582,
			},
			HL: {
				path: [
					[0.75, 5], [0.85, 9],
					[1, 11], [1.1, 12.6],
					[1.25, 14], [1.5, 16],
					[8, 16], [8, 7.8],
					[7, 6.4], [6, 5],
				],
				materials: ["aisip", "smop"],
				a: 0.0060319,
				b: -0.0581477,
				c: 0.83347,
				d: -1.71131,
			},
			RL: {
				path: [
					[0.4, 1.7], [0.4, 3],
					[0.5, 5], [0.75, 6.5],
					[4.15, 6.5], [5, 5],
					[5, 2.8], [4, 2.25],
					[2.38, 1.7],
				],
				a: 0.0563035,
				b: -0.0193721,
				c: 0.170232,
				d: 2.47163,
			},
			GL: {
				path: [
					[0.35, 1.8], [0.4, 3],
					[0.45, 4], [0.55, 5],
					[0.7, 6.5], [0.8, 7.5],
					[3.25, 7.5], [3.8, 6.5],
					[4, 6.15], [4.25, 5.8],
					[4.6, 5.4], [5, 5],
					[5, 2.8], [4, 2.15],
					[3, 1.8],
				],
				materials: ["aisip", "smop"],
				a: 0.0387043,
				b: -0.0863216,
				c: 0.580388,
				d: -0.373286,
			},
			JL: {
				path: [
					[0.4, 1.8], [0.45, 3],
					[0.55, 4], [0.65, 5],
					[0.75, 6], [3.5, 6],
					[5, 5], [5, 2.9],
					[3.55, 2], [3, 1.8],
				],
				materials: ["aisiu", "aisip", "smou", "smop"],
				a: -0.0185089,
				b: 0.550904,
				c: -1.48539,
				d: 1.46783,
			},
		},
		HWB: {
			LL: {
				path: [
					[0.42, 1.8], [0.5, 3],
					[0.62, 5], [4, 5],
					[4, 2.5], [2.4, 1.8],
				],
				a: 0.041685,
				b: -0.0697807,
				c: 0.538089,
				d: -0.406901,
			},
			UL: {
				path: [
					[0.05, 1], [0.08, 1.5],
					[0.13, 2.35], [0.2, 3],
					[2, 3], [2, 1.5],
					[1.25, 1],
				],
				a: -0.029585,
				b: 0.46259,
				c: -0.68396,
				d: 0.33096,
			},
			HL: {
				path: [
					[0.75, 5], [0.85, 9],
					[1, 11], [1.1, 12.6],
					[1.25, 14], [1.5, 16],
					[8, 16], [8, 7.8],
					[7, 6.4], [6, 5],
				],
				materials: ["aisip", "smop"],
				a: 0.0046916,
				b: -0.032213,
				c: 0.367543,
				d: -0.551007,
			},
			RL: {
				path: [
					[0.4, 1.7], [0.4, 3],
					[0.5, 5], [0.75, 6.5],
					[4.15, 6.5], [5, 5],
					[5, 2.8], [4, 2.25],
					[2.38, 1.7],
				],
				a: 0.0465246,
				b: 0.0254155,
				c: 0.0423696,
				d: -0.0053883,
			},
			GL: {
				path: [
					[0.35, 1.8], [0.4, 3],
					[0.45, 4], [0.55, 5],
					[0.7, 6.5], [0.8, 7.5],
					[3.25, 7.5], [3.8, 6.5],
					[4, 6.15], [4.25, 5.8],
					[4.6, 5.4], [5, 5],
					[5, 2.8], [4, 2.15],
					[3, 1.8],
				],
				materials: ["aisip", "smop"],
				a: 0.0241208,
				b: 0.008387199,
				c: 0.233868,
				d: -0.150298,
			},
			JL: {
				path: [
					[0.4, 1.8], [0.45, 3],
					[0.55, 4], [0.65, 5],
					[0.75, 6], [3.5, 6],
					[5, 5], [5, 2.9],
					[3.55, 2], [3, 1.8],
				],
				materials: ["aisiu", "aisip", "smou", "smop"],
				a: -0.0097716,
				b: 0.477943,
				c: -1.44766,
				d: 1.50873,
			},
		},
		GW: {
			UL: {
				path: [
					[0.1, 1], [0.16, 2],
					[0.3, 3], [0.5, 4],
					[4, 4], [4, 3.1],
					[3.5, 2.5], [3, 2],
					[2, 1.26], [1.6, 1],
				],
				a: 0.13774,
				b: -0.114476,
				c: -0.10231,
				d: 0.11904,
			},
			HL: {
				path: [
					[0.7, 4], [0.73, 5],
					[0.85, 9], [1, 11],
					[1.1, 12.6], [1.25, 14],
					[1.5, 16], [8, 16],
					[8, 7.8], [7, 6.4],
					[6, 5], [5, 4],
				],
				a: 0.0072252,
				b: -0.0775619,
				c: 0.8231,
				d: -1.42999,
				materials: ["aisip", "smop"],
			},
			RL: {
				path: [
					[0.4, 1.7], [0.4, 3],
					[0.5, 5], [0.75, 6.5],
					[4.15, 6.5], [5, 5],
					[5, 2.8], [4, 2.25],
					[2.38, 1.7],
				],
				a: 0.22959,
				b: -0.755408,
				c: 0.9011,
				d: -0.0144898,
			},
			GL: {
				path: [
					[0.35, 1.8], [0.4, 3],
					[0.45, 4], [0.55, 5],
					[0.7, 6.5], [0.8, 7.5],
					[3.25, 7.5], [3.8, 6.5],
					[4, 6.15], [4.25, 5.8],
					[4.6, 5.4], [5, 5],
					[5, 2.8], [4, 2.15],
					[3, 1.8],
				],
				materials: ["aisip", "smop"],
				a: 0.07031486,
				b: -0.0602716,
				c: 0.161343,
				d: 0.154811,
			},
		},
		RMPL: {
			UL: {
				path: [
					[0.04, 0.45], [0.08, 1.5],
					[0.13, 2.35], [0.2, 3],
					[2, 3], [2, 1.5],
					[0.8, 0.45],
				],
				a: 0.37593,
				b: -1.1973,
				c: 1.8361,
				d: -0.637916,
			},
			HL: {
				path: [
					[0.75, 5], [0.85, 9],
					[1, 11], [1.1, 12.6],
					[1.25, 14], [1.5, 16],
					[8, 16], [8, 7.8],
					[7, 6.4], [6, 5],
				],
				materials: ["aisip", "smop"],
				a: 0.0072252,
				b: -0.0775619,
				c: 0.8231,
				d: -1.42999,
			},
			RL: {
				path: [
					[0.4, 1.7], [0.4, 3],
					[0.5, 5], [0.75, 6.5],
					[4.15, 6.5], [5, 5],
					[5, 2.8], [4, 2.25],
					[2.38, 1.7],
				],
				a: 0.157434,
				b: -0.150721,
				c: 0.307737,
				d: 0.030857,
			},
		},
		RMPH: {
			LL: {
				path: [
					[0.4, 1.5], [0.43, 2.5],
					[0.48, 3.5], [0.8, 5.5],
					[5, 5.5], [5, 2.85],
					[3.5, 2], [2.3, 1.5],
				],
				a: 0.618214,
				b: -2.5243,
				c: 4.93487,
				d: -2.60671,
			},
			UL: {
				path: [
					[0.04, 0.45], [0.08, 1.5],
					[0.13, 2.35], [0.2, 3],
					[2, 3], [2, 1.5],
					[0.8, 0.45],
				],
				a: 0.34818,
				b: -0.536788,
				c: 0.698138,
				d: -0.234525,
			},
			HL: {
				path: [
					[0.7, 4], [0.73, 5],
					[0.85, 9], [1, 11],
					[1.1, 12.6], [1.25, 14],
					[1.5, 16], [8, 16],
					[8, 7.8], [7, 6.4],
					[6, 5], [5, 4],
				],
				materials: ["aisip", "smop"],
				a: 0.008144,
				b: -0.0811676,
				c: 0.829941,
				d: -1.45733,
			},
			LS: {
				path: [
					[0.4, 3], [0.5, 4.2],
					[0.8, 6], [4, 6],
					[5, 5], [5, 3.9],
					[3.6, 3.4], [2.3, 3],
				],
				a: 0.174139,
				b: -0.39299,
				c: 0.342778,
				d: 0.271811,
			},
			GL: {
				path: [
					[0.4, 1.5], [0.5, 3],
					[0.6, 5], [0.7, 6],
					[3.5, 6], [4, 5.5],
					[4, 2.3], [3, 1.7],
					[2.25, 1.5],
				],
				materials: ["aisip", "smop"],
				a: 0.355556,
				b: -1.01667,
				c: 1.75516,
				d: -0.774999,
			},
		},
		ROCCU: {
			WS: {
				path: [
					[0.42, 2], [0.48, 4],
					[0.8, 8], [3, 8],
					[4, 7], [4, 3.5],
					[3.8, 2.9], [3.5, 2.5],
					[2.9, 2.1], [2.25, 2],
				],
				materials: ["aisip"],
				a: -0.011551,
				b: 0.455614,
				c: -1.13387,
				d: 0.948358,
			},
		},
		RCFU: {
			WS: {
				path: [
					[0.42, 2], [0.48, 4],
					[0.8, 8], [3, 8],
					[4, 7], [4, 3.5],
					[3.8, 2.9], [3.5, 2.5],
					[2.9, 2.1], [2.25, 2],
				],
				materials: ["aisip"],
				a: 0.049009,
				b: -0.40297,
				c: 1.6113,
				d: -1.7132,
			},
		},
		ROCCS: {
			UL: {
				path: [
					[0.05, 1], [0.08, 1.5],
					[0.13, 2.35], [0.2, 3],
					[2, 3], [2, 1.5],
					[1.25, 1],
				],
				a: 0.0251579,
				b: 0.348803,
				c: -0.2287,
				d: 0.0747348,
			},
			HL: {
				path: [
					[0.7, 4], [0.73, 5],
					[0.85, 9], [1, 11],
					[1.1, 12.6], [1.25, 14],
					[1.5, 16], [8, 16],
					[8, 7.8], [7, 6.4],
					[6, 5], [5, 4],
				],
				materials: ["aisip", "smop"],
				a: 0.007472,
				b: -0.102805,
				c: 1.02565,
				d: -2.197873,
			},
			RL: {
				path: [
					[0.4, 1.45], [0.52, 4],
					[0.80, 7], [3.65, 7],
					[5, 5], [5, 2.8],
					[4, 2.25], [3.5, 2],
					[2.8, 1.7], [2.25, 1.45],
				],
				a: 0.0487374,
				b: -0.0873377,
				c: 0.499206,
				d: -0.0575758,
			},
			GL: {
				path: [
					[0.35, 1.8], [0.4, 3],
					[0.45, 4], [0.55, 5],
					[0.7, 6.5], [0.9, 8],
					[3, 8], [3.25, 7.5],
					[3.8, 6.5], [4, 6.15],
					[4.25, 5.8], [4.6, 5.4],
					[5, 5], [5, 2.8],
					[4, 2.15], [3, 1.8],
				],
				materials: ["aisip", "smop"],
				a: 0.048384,
				b: -0.1508036,
				c: 0.561967,
				d: -0.185833,
			},
		},
		RCFS: {
			UL: {
				path: [
					[0.1, 1], [0.2, 2],
					[0.3, 3], [0.45, 4],
					[0.7, 5], [4, 5],
					[3.5, 4], [3, 3],
					[2.3, 2], [2, 1.6],
					[1.5, 1],
				],
				a: 0.038470659,
				b: -0.08406156,
				c: 0.40412,
				d: -0.14181,
			},
			HL: {
				path: [
					[0.7, 4], [0.73, 5],
					[0.85, 9], [1, 11],
					[1.1, 12.6], [1.25, 14],
					[1.5, 16], [8, 16],
					[8, 7.8], [7, 6.4],
					[6, 5], [5, 4],
				],
				materials: ["aisip", "smop"],
				a: 0.0028749,
				b: -0.0163148,
				c: 0.32551,
				d: -0.5071243,
			},
			RL: {
				path: [
					[0.4, 1.7], [0.52, 4],
					[0.68, 5.48], [0.7, 6],
					[0.85, 7.45], [0.95, 8],
					[3, 8], [3.65, 7],
					[5, 5], [5, 2.8],
					[4, 2.25], [3.5, 2],
					[2.8, 1.7],
				],
				a: 0.0163997,
				b: 0.102246,
				c: -0.0390311,
				d: 0.0382473,
			},
			GL: {
				path: [
					[0.35, 1.8], [0.4, 3],
					[0.45, 4], [0.55, 5],
					[0.7, 6.5], [0.9, 8],
					[3, 8], [3.25, 7.5],
					[3.8, 6.5], [4, 6.15],
					[4.25, 5.8], [4.6, 5.4],
					[5, 5], [5, 2.8],
					[4, 2.15], [3, 1.8],
				],
				materials: ["aisip", "smop"],
				a: 0.0197088,
				b: 0.0329727,
				c: -0.0873044,
				d: 0.277301,
			},
		},
		CTMP: {
			LL: {
				path: [
					[0.4, 1.5], [0.43, 2.5],
					[0.48, 3.5], [0.8, 5.5],
					[5, 5.5], [5, 2.85],
					[3.5, 2], [2.3, 1.5],
				],
				a: -0.0166667,
				b: 0.639286,
				c: -0.858333,
				d: 1.19999,
			},
			UL: {
				path: [
					[0.04, 0.45], [0.08, 1.5],
					[0.13, 2.35], [0.2, 3],
					[2, 3], [2, 1.5],
					[0.8, 0.45],
				],
				a: 0.37593,
				b: -1.1973,
				c: 1.8361,
				d: -0.637916,
			},
			HL: {
				path: [
					[0.7, 4], [0.73, 5],
					[0.85, 9], [1, 11],
					[1.1, 12.6], [1.25, 14],
					[1.5, 16], [8, 16],
					[8, 7.8], [7, 6.4],
					[6, 5], [5, 4],
				],
				materials: ["aisip", "smop"],
				a: 0.0072252,
				b: -0.0775619,
				c: 0.8231,
				d: -1.42999,
			},
			LS: {
				path: [
					[0.4, 3], [0.5, 4.2],
					[0.8, 6], [4, 6], [5, 5],
					[5, 3.9], [3.6, 3.4],
					[2.3, 3],
				],
				a: 0.0342227,
				b: 1.10222,
				c: -5.87376,
				d: 8.74182,
			},
			RL: {
				path: [
					[0.4, 1.45], [0.52, 4],
					[0.68, 5.48], [4.6, 5.48],
					[5, 5], [5, 2.8],
					[4, 2.25], [3.5, 2],
					[2.8, 1.7], [2.25, 1.45],
				],
				a: 0.0619695,
				b: 0.253036,
				c: -0.194367,
				d: 0.026773,
			},
		},
	};

	public static getRecommendedSensor(pulp: keyof typeof PulpNames): keyof typeof allSENSORS {
		let sensor: keyof typeof allSENSORS = "RL";
		switch (pulp) {
			case "CTMP":
				sensor = "RL";
				break;
			case "GW":
				sensor = "RL";
				break;
			case "HWB":
				sensor = "RL";
				break;
			case "HWU":
				sensor = "RL";
				break;
			case "RCFS":
				sensor = "RL";
				break;
			case "RCFU":
				sensor = "WS";
				break;
			case "RMPH":
				sensor = "LL";
				break;
			case "RMPL":
				sensor = "RL";
				break;
			case "ROCCS":
				sensor = "RL";
				break;
			case "ROCCU":
				sensor = "WS";
				break;
			case "SWB":
				sensor = "RL";
				break;
			case "SWU":
				sensor = "RL";
				break;
			default:
				break;
		}
		return sensor;
	}

	/**
	 * Function to get the sensors available with a given pulp.
	 * Special case when material is switched to fiber glass pipes.
	 *
	 * @param pulp Pulptype to get sensors for.
	 * @param pipeMaterial Pipe material.
	 */
	public static getPulpSensors(
		pulp: keyof typeof PulpNames,
		pipeMaterial: keyof typeof PipeType,
	): (keyof typeof allSENSORS)[] {
		let keys = Object.keys(PulpData.ThePulp[pulp]) as (keyof typeof allSENSORS)[];
		keys = keys.filter(item => {
			if (pipeMaterial === "f") {
				if (item === "JL") {
					return true;
				}
				return false;
			} else {
				if (item === "JL") {
					return false;
				}
				return true;
			}
		});
		return keys;
	}

	/**
	 * Object to check which pulps use alternate pathing at certain velocity values.
	 * Checked on "getSensorPath".
	 */
	private static alternateHLPath = {
		SWU: true,
		SWB: true,
		GW: true,
		CTMP: true,
		RCFS: true,
		ROCCS: true,
		RMPH: true,
		HWU: false,
		HWB: false,
		RMPL: false,
		ROCCU: false,
		RCFU: false,
	};

	/**
	 * Returns the selectable sensor material list.
	 *
	 * @param pulp Pulp type.
	 * @param sensor Sensor type.
	 */
	public static getMaterialsList(
		pulp: keyof typeof PulpNames,
		sensor: keyof typeof allSENSORS,
	): {[key: string]: string } {
		let mats = PulpData.ThePulp[pulp][sensor]?.materials;
		let output: {[key: string]: string } = {};
		if (!mats) {
			return MATERIALS;
		}
		mats.forEach(key => {
			output[key] = MATERIALS[key];
		});
		return output;
	}

	/**
	 * Function to get the sensor's canvas drawing path coordinates.
	 *
	 * @param pulp Pulp type.
	 * @param sensor Sensor type.
	 * @param vMin velocity min value.
	 * @param vMax velocity max value.
	 */
	public static getSensorPath(
		pulp: keyof typeof PulpNames,
		sensor: keyof typeof allSENSORS,
		vMin: number,
		vMax: number,
	): [number, number][] {
		let path = PulpData.ThePulp[pulp][sensor]?.path;
		if (!path) {
			path = [];
		}
		if (sensor === "HL" && vMin > 0 && vMax / vMin < 2) {
			if (PulpData.alternateHLPath[pulp]) {
				path = [
					[0.4, 4], [0.4, 5],
					[0.48, 9], [0.5, 11],
					[0.6, 12.5], [0.7, 14],
					[0.8, 15], [0.85, 16],
					[8, 16], [8, 7.8],
					[7, 6.4], [6, 5],
					[5, 4],
				];
			}
		}
		return path;
	}

	/**
	 * Calculates the maximum X value needed for the canvas grid.
	 *
	 * @param pulp Pulp type.
	 * @param sensor Sensor type.
	 */
	public static maxX(
		pulp: keyof typeof PulpNames,
		sensor: keyof typeof allSENSORS,
	): number {
		let path = PulpData.ThePulp[pulp][sensor]?.path;
		let maxX = 0;
		if (!path) {
			path = [];
			return maxX;
		}
		for (let i = 0; i < path.length; i++) {
			if (path[i][0] > maxX) {
				maxX = path[i][0];
			}
		}
		return maxX;
	}

	/**
	 * Calculates the maximum Y value needed for the canvas grid.
	 *
	 * @param pulp Pulp type.
	 * @param sensor Sensor type.
	 */
	public static maxY(
		pulp: keyof typeof PulpNames,
		sensor: keyof typeof allSENSORS,
	): number {
		let path = PulpData.ThePulp[pulp][sensor]?.path;
		let maxY = 0;
		if (!path) {
			path = [];
			return maxY;
		}
		for (let i = 0; i < path.length; i++) {
			if (path[i][1] > maxY) {
				maxY = path[i][1];
			}
		}
		return maxY;
	}

	/**
	 * Gets the pulp & sensor combinations calculation values
	 * No idea what they are as they were ever named as a,b,c,d.
	 *
	 * @param pulp pulp type.
	 * @param sensor sensor type.
	 */
	public static getSensorValues = (
		pulp: keyof typeof PulpNames,
		sensor: keyof typeof allSENSORS,
	) => {
		let a = PulpData.ThePulp[pulp][sensor]!.a;
		let b = PulpData.ThePulp[pulp][sensor]!.b;
		let c = PulpData.ThePulp[pulp][sensor]!.c;
		let d = PulpData.ThePulp[pulp][sensor]!.d;
		return { a, b, c , d };
	}

	/**
	 * Unit multipliers for calculations.
	 * Default returns are for the metric default units.
	 */

	public static flowUnitMultiplier = (unit: keyof typeof FlowUnit) => {
		if (unit === 'lmin') {
			return 0.016667;

		} else if (unit === 'usgpm') {
			return 0.063091482;

		} else if (unit === 'm3h') {
			return 0.27777778;
		}
		return 1;
	}

	public static velocityUnitMultiplier = (unit: keyof typeof VelocityUnit) => {
		if (unit === 'ft') {
			return 0.3048;
		}
		return 1;
	}

	public static pipeMultiplier = (unit: keyof typeof PipeUnit) => {
		if (unit === 'in') {
			return 25.4;
		}
		return 1;
	}

	/**
	 * Returns the constant 'kz' used in calculations.
	 * The return value depends of the sensor-type, material and mounting.
	 * (Copied from old visual basic code. Don't know how to name this)
	 *
	 * @param mounting mount type.
	 * @param material sensor material.
	 * @param sensor sensor type.
	 */
	public static kz(
		mounting: keyof typeof Mounting,
		material: keyof typeof MATERIALS,
		sensor: keyof typeof allSENSORS,
	): number {
		let kz = 0.0; // fallback value.. (?)
		if (mounting === "A" || mounting === "D") {
			if (material.match(/aisiu|aisip|smou|smop/)) {
				if (sensor === "LL") {
					kz = -0.9;
				} else if (sensor === "RL") {
					kz = -1.3;
				} else if (sensor === "LS") {
					kz = 1;
				} else if (sensor === "GL") {
					kz = 0.7;
				} else if (sensor === "HL") {
					kz = 5.2;
				} else if (sensor === "WS") {
					kz = -1.9;
				} else if (sensor === "JL") {
					kz = 0.4;
				}
			}
		} else if (mounting === "B1" || mounting === "C") {
			if (material.match(/aisiu|aisip|smou|smop/)) {
				if (sensor === "LL") {
					kz = -0.5;
				} else if (sensor === "RL") {
					kz = -0.8;
				} else if (sensor === "LS") {
					kz = 0.4;
				} else if (sensor === "GL") {
					kz = 0.2;
				} else if (sensor === "HL") {
					kz = 2.4;
				} else if (sensor === "WS") {
					kz = -1.05;
				} else if (sensor === "JL") {
					kz = 0;
				}
			}
		} else if (mounting === "B2") {
			if (material.match(/aisiu|aisip|smou|smop/)) {
				if (sensor === "LL") {
					kz = -0.2;
				} else if (sensor === "RL") {
					kz = -2.9;
				} else if (sensor === "LS") {
					kz = 0.4;
				} else if (sensor === "GL") {
					kz = 0.3;
				} else if (sensor === "HL") {
					kz = 6.2;
				} else if (sensor === "WS") {
					kz = -2.8;
				} else if (sensor === "JL") {
					kz = 1;
				}
			}
		}
		return kz;
	}

	/**
	 *	Calculates the pipe flow values for all the different setups.
	 */
	public static calculatePipeValues(
		input: ConsistencyData,
	): PipeValues {
		let mountingKeys = Object.keys(Mounting);
		let output: PipeValues = {};
		const cMin = input.consistencyMin === "" ? 0 : input.consistencyMin;
		const cMax = input.consistencyMax === "" ? 0 : input.consistencyMax;
		const vMax = input.velocityMax === "" ? 0 : input.velocityMax;
		const pipeDiameter = input.pipeDiameter === "" ? 100 : input.pipeDiameter;
		mountingKeys.forEach((mounting: keyof typeof Mounting) => {
			let k = (8 - cMin) * Math.pow((vMax
				* PulpData.velocityUnitMultiplier(input.velocityUnit)), 0.62)
				+ 1 / (cMin + 0.6);

			let mount_k = k;
			if (mount_k < 3) {
				mount_k = 3;
			}
			let L1 = mount_k * pipeDiameter * PulpData.pipeMultiplier(input.pipeUnit);
			const L2 = (0.3 * L1 + 250) / PulpData.pipeMultiplier(input.pipeUnit);

			if (mounting === 'B1') {
				L1 = 1.5 * L1 / PulpData.pipeMultiplier(input.pipeUnit);
			} else {
				L1 = L1 / PulpData.pipeMultiplier(input.pipeUnit);
			}

			const values = PulpData.getSensorValues(input.pulpType, input.sensorType);
			const a = values.a;
			const b = values.b;
			const c = values.c;
			const d = values.d;

			const kz = PulpData.kz(mounting, input.sensorMaterial, input.sensorType);
			const F1 = a * Math.pow(cMin, 3) + b * Math.pow(cMin, 2) + c * cMin + d;
			const F2 = a * Math.pow(cMax, 3) + b * Math.pow(cMax, 2) + c * cMax + d;

			let zsimw = (F2 + kz) * 100;
			const ssimw = (F2 - F1) * 100;
			if (input.sensorType === 'UL') {
				zsimw = (F2 - F1) * 100;
			}
			output[mounting] = { "L1": L1, "L2": L2, "zsimw": zsimw, "ssimw": ssimw, "k": k };
		});
		return output;
	}
}
