import { FlowUnit, PipeUnit, PressureUnit, ProductionUnit, VelocityUnit } from "../Constants";

export class DilutionUtility {

	/**
	 * Returns a unit specific factor used in calculations
	 *
	 */
	public static flow_x(unit: keyof typeof FlowUnit): number {
		let x = 1.0;
		switch (unit) {
			case 'm3h':
				x = 4.403;
				break;
			case 'lmin':
				x = 0.26417;
				break;
		}
		return x;
	}

	/**
	 * @head2 velocity_x( unit )
	 *
	 * Returns a unit specific factor used in calculations
	 *
	 */
	public static velocity_x(unit: keyof typeof VelocityUnit): number {
		let x = 1.0;
		switch (unit) {
			case 'm':
				x = 3.281;
				break;
		}
		return x;
	}

	/**
	 * @head2 pipe_x( unit )
	 *
	 * Returns a unit specific factor used in calculations
	 *
	 */
	public static pipe_x(unit: keyof typeof PipeUnit): number {
		let x = 1.0;
		switch (unit) {
			case 'mm':
				x = 0.03937;
				break;
		}
		return x;
	}

	/**
	 * @head2 pressure_x( unit )
	 *
	 * Returns a unit specific factor used in calculations
	 *
	 */
	public static pressure_x(unit: keyof typeof PressureUnit): number {
		let x = 1.0;
		switch (unit) {
			case 'bar':
				x = 14.504;
				break;
			case 'kpa':
				x = 0.145;
				break;
			default:
				x = 1.0;
		}
		return x;
	}

	/**
	 * @head2 coeff_x( unit )
	 *
	 * Returns a unit specific factor used in calculations
	 *
	 */
	public static production_x(unit: keyof typeof ProductionUnit): number {
		let x = 1.0;
		switch (unit) {
			case 'metric':
				x = 1.102;
				break;
			case 'uk':
				x = 1.12;
				break;
		}
		return x;
	}

	public static flow_d_x(unit: keyof typeof FlowUnit): number {
		let x = 1.0;
		switch (unit) {
			case 'm3h':
				x = 4.403;
				break;
			case 'lmin':
				x = 0.26417;
				break;
			default:
				x = 1.0;
		}
		return x;
	}

	public static beforeFlow(
		beforeFlowFactor: number,
		beforeConsistency: number,
		productionValue: number,
	): number {
		const x = ((16.65 / beforeFlowFactor) / beforeConsistency) * productionValue;
		return x;
	}

	public static beforeVelocity(
		beforeFlowFactor: number,
		beforeFlow: number,
		pipeDiameter: number,
		pipeFactor: number,
		beforeVelocityFactor: number,
	): number {
		const x = (beforeFlowFactor * beforeFlow) /
			(Math.pow((pipeDiameter * pipeFactor), 2) * (2.45 * beforeVelocityFactor));
		return x;
	}

	public static afterFlow(
		afterFlowFactor: number,
		afterConsistency: number,
		productionValue: number,
	): number {
		const x = ((16.65 / afterFlowFactor) / afterConsistency) * productionValue;
		return x;
	}

	public static afterVelocity(
		afterFlowFactor: number,
		afterFlow: number,
		pipeDiameter: number,
		pipeFactor: number,
		afterVelocityFactor: number,
	): number {
		const x = (afterFlowFactor * afterFlow) /
			(Math.pow((pipeDiameter * pipeFactor), 2) * (2.45 * afterVelocityFactor));
		return x;
	}

	public static productionValue(
		productionPerDay: number,
		productionUnitFactor: number,
	): number {
		const x = productionPerDay * productionUnitFactor;
		return x;
	}

	public static pumpVelocity(
		dilutionWaterNeeded: number,
		dilutionWaterFactor: number,
		pumpDiameter: number,
		pumpDiameterFactor: number,
	): number {
		const x = dilutionWaterNeeded * dilutionWaterFactor /
			(Math.pow((pumpDiameter * pumpDiameterFactor), 2) * 2.45);
		return x;
	}

	public static dilutionWaterNeed(
		afterFlow: number,
		afterFlowFactor: number,
		beforeFlow: number,
		beforeFlowFactor: number,
		dilutionWaterFactor: number,
	): number {
		const x = (afterFlow * afterFlowFactor - beforeFlow * beforeFlowFactor) / dilutionWaterFactor;
		return x;
	}

	public static dilutionWaterCV(
		dilutionWaterNeeded: number,
		dilutionWaterFactor: number,
		pressureDrop: number,
		pressureFactor: number,
	): number {
		const x = dilutionWaterNeeded * dilutionWaterFactor / Math.sqrt(pressureDrop * pressureFactor);
		return x;
	}
}
