import * as React from "react";
import { consistencydefaults, ConsistencyData } from "../Constants";

type Dispatch = React.Dispatch<React.SetStateAction<ConsistencyData>>;

const ConsistencyState
	= React.createContext<ConsistencyData | undefined>(undefined);
const UpdateConsistency
	= React.createContext<Dispatch | undefined>(undefined);

/**
 * Context provider as a component. Renders it's children
 * inside it's context.Providers.
 */
export const ConsistencyProvider: React.FC<{}> = ({children}) => {
	const dataKey = "consistencyData";
	let initialValues = consistencydefaults;
	// Load initial values from localStorage if available.
	if ('localStorage' in window && window['localStorage'] !== null) {
		const localStr = window.localStorage;
		try {
			const storedState = localStr.getItem(dataKey);
			if (storedState) {
				initialValues = JSON.parse(storedState);
			}
		} catch (e) {
			console.warn(e);
		}
	}
	const [ccState, updateCCState] = React.useState(initialValues);

	// Save updates to localStorage if available.
	const dataUpdate = (state: ConsistencyData) => {
		if ('localStorage' in window && window['localStorage'] !== null) {
			const localStr = window.localStorage;
			try {
				localStr.setItem(dataKey, JSON.stringify(state));
			} catch (e) {
				console.warn(e);
			}
		}
		updateCCState(state);
	};

	return (
		<ConsistencyState.Provider value={ccState}>
			<UpdateConsistency.Provider value={dataUpdate}>
				{children}
			</UpdateConsistency.Provider>
		</ConsistencyState.Provider>
	);
};

/**
 * Function for loading the context state from a child component.
 * Outside the provider leads to an error.
 */
export const useConsistencyState: () => ConsistencyData = () => {
	const context = React.useContext(ConsistencyState);
	if (context === undefined) {
		throw new Error('useConsistencyState must be used within provider');
	}
	return context;
};

/**
 * Function for loading the context state update function from a child component.
 * Outside the provider leads to an error.
 */
export const useUpdateConsistency: () => Dispatch = () => {
	const context = React.useContext(UpdateConsistency);
	if (context === undefined) {
		throw new Error('useUpdateConsistency must be used within provider');
	}
	return context;
};
