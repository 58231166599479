import * as React from "react";
import { DilutionData, dilutionDefaults } from "../Constants";
import { AfterDilution } from "./AfterDilution";
import { BeforeDilution } from "./BeforeDilution";
import { useDilutionState, useUpdateDilution } from "./DilutionContext";
import { DilutionUtility } from "./DilutionUtility";
import { DilutionValues } from "./DilutionValues";
import { ImageWrapper } from "./ImageWrapper";
import { Production } from "./Production";
import { UnitConverter } from "./UnitConverter";
import { Units } from "./Units";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

export type ItemProps = {
	updateValue: (value: string, key: keyof DilutionData) => void;
	updateUnit: (
		event: React.ChangeEvent<HTMLSelectElement>,
		key: keyof DilutionData,
	) => void;
	margin?: string;
};

/**
 * Handles the value updates on context and calculation calls.
 * TODO: useEffect updates not working 100%.
 */
export const ItemsContainer: React.FC<{}> = () => {
	const context = useDilutionState();
	const updateDilution = useUpdateDilution();

	const updateContextValue = (value: string, key: keyof DilutionData) => {
		updateDilution({ ...context, [key]: value });
	};

	const updateContextUnit = (
		event: React.ChangeEvent<HTMLSelectElement>,
		key: keyof DilutionData,
	) => {
		const val = event.target.value;
		updateDilution({ ...context, [key]: val });
	};

	const calculateBeforeFlow = () => {
		let beforeFlow = context.beforeFlow;
		if (
			context.productionPerDay === "" ||
			context.beforeConsistency === ""
		) {
			return beforeFlow;
		}
		const productionFactor = DilutionUtility.production_x(
			context.productionUnit,
		);
		const productionValue = DilutionUtility.productionValue(
			context.productionPerDay,
			productionFactor,
		);
		const flowFactor = DilutionUtility.flow_x(context.flowUnit);
		beforeFlow = DilutionUtility.beforeFlow(
			flowFactor,
			context.beforeConsistency,
			productionValue,
		);
		if (typeof beforeFlow !== "number") {
			beforeFlow = 0;
		}
		return beforeFlow;
	};

	const calculateBeforeVelocity = () => {
		let beforeVelocity = context.beforeVelocity;
		if (context.beforeFlow === "" || context.pipeDiameter === "") {
			return beforeVelocity;
		}
		const flowFactor = DilutionUtility.flow_x(context.flowUnit);
		const velocityFactor = DilutionUtility.velocity_x(context.velocityUnit);
		const pipeFactor = DilutionUtility.pipe_x(context.pipeDiameterUnit);
		const beforeFlow = context.beforeFlow;
		const pipeDiameter = context.pipeDiameter;
		beforeVelocity = DilutionUtility.beforeVelocity(
			flowFactor,
			beforeFlow,
			pipeDiameter,
			pipeFactor,
			velocityFactor,
		);
		if (typeof beforeVelocity !== "number") {
			beforeVelocity = 0;
		}
		return beforeVelocity;
	};

	const calculateAfterFlow = () => {
		let afterFlow = context.afterFlow;
		if (
			context.productionPerDay === "" ||
			context.afterConsistency === ""
		) {
			return afterFlow;
		}
		const productionFactor = DilutionUtility.production_x(
			context.productionUnit,
		);
		const productionValue = DilutionUtility.productionValue(
			context.productionPerDay,
			productionFactor,
		);
		const flowFactor = DilutionUtility.flow_x(context.flowUnit);
		afterFlow = DilutionUtility.afterFlow(
			flowFactor,
			context.afterConsistency,
			productionValue,
		);
		if (typeof afterFlow !== "number") {
			afterFlow = 0;
		}
		return afterFlow;
	};

	const calculateAfterVelocity = () => {
		let afterVelocity = context.afterVelocity;
		if (context.afterFlow === "" || context.pipeDiameter === "") {
			return afterVelocity;
		}
		const flowFactor = DilutionUtility.flow_x(context.flowUnit);
		const velocityFactor = DilutionUtility.velocity_x(context.velocityUnit);
		const pipeFactor = DilutionUtility.pipe_x(context.pipeDiameterUnit);
		const afterFlow = context.afterFlow;
		const pipeDiameter = context.pipeDiameter;
		afterVelocity = DilutionUtility.afterVelocity(
			flowFactor,
			afterFlow,
			pipeDiameter,
			pipeFactor,
			velocityFactor,
		);
		if (typeof afterVelocity !== "number") {
			afterVelocity = 0;
		}
		return afterVelocity;
	};

	const calculateDilutionWater = () => {
		let dilutionWater = context.dilutionWater;
		if (context.afterFlow === "" || context.beforeFlow === "") {
			return dilutionWater;
		}
		const afterFlow = context.afterFlow;
		const beforeFlow = context.beforeFlow;
		const flowFactor = DilutionUtility.flow_x(context.flowUnit);
		const dilutionFactor = DilutionUtility.flow_x(context.dilutionUnit);
		dilutionWater = DilutionUtility.dilutionWaterNeed(
			afterFlow,
			flowFactor,
			beforeFlow,
			flowFactor,
			dilutionFactor,
		);
		if (typeof dilutionWater !== "number") {
			dilutionWater = 0;
		}
		return dilutionWater;
	};

	const calculateDilutionWaterCV = () => {
		let dilutionWaterCV = context.dilutionWaterCV;
		if (context.dilutionWater === "" || context.pressureDrop === "") {
			return dilutionWaterCV;
		}
		const dilutionWater = context.dilutionWater;
		const dilutionFactor = DilutionUtility.flow_x(context.dilutionUnit);
		const pressureDrop = context.pressureDrop;
		const pressureDropFactor = DilutionUtility.pressure_x(
			context.pressureDropUnit,
		);
		dilutionWaterCV = DilutionUtility.dilutionWaterCV(
			dilutionWater,
			dilutionFactor,
			pressureDrop,
			pressureDropFactor,
		);
		if (typeof dilutionWaterCV !== "number") {
			dilutionWaterCV = 0;
		}
		return dilutionWaterCV;
	};

	const calculatePumpVelocity = () => {
		let pumpVelocity = context.pumpVelocity;
		if (context.dilutionWater === "" || context.pumpDiameter === "") {
			return pumpVelocity;
		}
		const dilutionWater = context.dilutionWater;
		const dilutionFactor = DilutionUtility.flow_x(context.dilutionUnit);
		const pumpDiameter = context.pumpDiameter;
		const pumpFactor = DilutionUtility.pipe_x(context.pumpDiameterUnit);
		pumpVelocity = DilutionUtility.pumpVelocity(
			dilutionWater,
			dilutionFactor,
			pumpDiameter,
			pumpFactor,
		);
		if (typeof pumpVelocity !== "number") {
			pumpVelocity = 0;
		}
		return pumpVelocity;
	};

	const calculateFlowUnitChange = () => {
		let beforeFlow = calculateBeforeFlow();
		let afterFlow = calculateAfterFlow();
		let beforeVelocity = context.beforeVelocity;
		if (beforeFlow !== "" && context.pipeDiameter !== "") {
			const flowFactor = DilutionUtility.flow_x(context.flowUnit);
			const velocityFactor = DilutionUtility.velocity_x(
				context.velocityUnit,
			);
			const pipeFactor = DilutionUtility.pipe_x(context.pipeDiameterUnit);
			const pipeDiameter = context.pipeDiameter;
			beforeVelocity = DilutionUtility.beforeVelocity(
				flowFactor,
				beforeFlow,
				pipeDiameter,
				pipeFactor,
				velocityFactor,
			);
		}
		let afterVelocity = context.afterVelocity;
		if (afterFlow !== "" && context.pipeDiameter !== "") {
			const flowFactor = DilutionUtility.flow_x(context.flowUnit);
			const velocityFactor = DilutionUtility.velocity_x(
				context.velocityUnit,
			);
			const pipeFactor = DilutionUtility.pipe_x(context.pipeDiameterUnit);
			const pipeDiameter = context.pipeDiameter;
			afterVelocity = DilutionUtility.afterVelocity(
				flowFactor,
				afterFlow,
				pipeDiameter,
				pipeFactor,
				velocityFactor,
			);
		}
		let dilutionWater = context.dilutionWater;
		if (afterFlow !== "" && beforeFlow !== "") {
			const flowFactor = DilutionUtility.flow_x(context.flowUnit);
			const dilutionFactor = DilutionUtility.flow_x(context.dilutionUnit);
			dilutionWater = DilutionUtility.dilutionWaterNeed(
				afterFlow,
				flowFactor,
				beforeFlow,
				flowFactor,
				dilutionFactor,
			);
		}
		if (typeof beforeVelocity !== "number") {
			beforeVelocity = 0;
		}
		if (typeof dilutionWater !== "number") {
			dilutionWater = 0;
		}
		afterFlow = decimalRounding(afterFlow);
		beforeFlow = decimalRounding(beforeFlow);
		beforeVelocity = decimalRounding(beforeVelocity);
		dilutionWater = decimalRounding(dilutionWater);
		updateDilution({
			...context,
			afterFlow,
			afterVelocity,
			beforeFlow,
			beforeVelocity,
			dilutionWater,
		});
	};

	const calculateDilutionUnitChange = () => {
		let dilutionWater = calculateDilutionWater();
		let dilutionWaterCV = context.dilutionWaterCV;
		if (dilutionWater !== "" && context.pressureDrop !== "") {
			const dilutionFactor = DilutionUtility.flow_x(context.dilutionUnit);
			const pressureDrop = context.pressureDrop;
			const pressureDropFactor = DilutionUtility.pressure_x(
				context.pressureDropUnit,
			);
			dilutionWaterCV = DilutionUtility.dilutionWaterCV(
				dilutionWater,
				dilutionFactor,
				pressureDrop,
				pressureDropFactor,
			);
		}
		let pumpVelocity = context.pumpVelocity;
		if (dilutionWater !== "" && context.pumpDiameter !== "") {
			const dilutionFactor = DilutionUtility.flow_x(context.dilutionUnit);
			const pumpDiameter = context.pumpDiameter;
			const pumpFactor = DilutionUtility.pipe_x(context.pumpDiameterUnit);
			pumpVelocity = DilutionUtility.pumpVelocity(
				dilutionWater,
				dilutionFactor,
				pumpDiameter,
				pumpFactor,
			);
		}
		if (typeof dilutionWaterCV !== "number") {
			dilutionWaterCV = 0;
		}
		if (typeof pumpVelocity !== "number") {
			pumpVelocity = 0;
		}
		dilutionWater = decimalRounding(dilutionWater);
		dilutionWaterCV = decimalRounding(dilutionWaterCV);
		pumpVelocity = decimalRounding(pumpVelocity);
		updateDilution({
			...context,
			dilutionWater,
			dilutionWaterCV,
			pumpVelocity,
		});
	};

	/**
	 * Rounding the calculated values to 2 decimals.
	 *
	 * @param value given value to round.
	 */
	const decimalRounding = (value: number | "") => {
		if (value !== "") {
			value = parseFloat(value.toFixed(2));
		}
		return value;
	};

	const clearForm = () => {
		updateDilution({ ...dilutionDefaults });
	};

	React.useEffect(() => {
		const beforeVelocity = decimalRounding(calculateBeforeVelocity());
		const afterVelocity = decimalRounding(calculateAfterVelocity());
		updateDilution({ ...context, beforeVelocity, afterVelocity });
	}, [context.pipeDiameter, context.pipeDiameterUnit]);

	React.useEffect(() => {
		const beforeVelocity = decimalRounding(calculateBeforeVelocity());
		const afterVelocity = decimalRounding(calculateAfterVelocity());
		updateDilution({ ...context, beforeVelocity, afterVelocity });
	}, [context.velocityUnit]);

	React.useEffect(() => {
		const beforeFlow = decimalRounding(calculateBeforeFlow());
		const afterFlow = decimalRounding(calculateAfterFlow());
		updateDilution({ ...context, beforeFlow, afterFlow });
	}, [context.productionPerDay, context.productionUnit]);

	React.useEffect(() => {
		const beforeFlow = decimalRounding(calculateBeforeFlow());
		updateDilution({ ...context, beforeFlow });
	}, [context.beforeConsistency]);

	React.useEffect(() => {
		const afterFlow = decimalRounding(calculateAfterFlow());
		updateDilution({ ...context, afterFlow });
	}, [context.afterConsistency]);

	React.useEffect(() => {
		const beforeVelocity = decimalRounding(calculateBeforeVelocity());
		const dilutionWater = decimalRounding(calculateDilutionWater());
		updateDilution({ ...context, beforeVelocity, dilutionWater });
	}, [context.beforeFlow]);

	React.useEffect(() => {
		const afterVelocity = decimalRounding(calculateAfterVelocity());
		const dilutionWater = decimalRounding(calculateDilutionWater());
		updateDilution({ ...context, afterVelocity, dilutionWater });
	}, [context.afterFlow]);

	React.useEffect(() => {
		calculateFlowUnitChange();
	}, [context.flowUnit]);

	React.useEffect(() => {
		const dilutionWaterCV = decimalRounding(calculateDilutionWaterCV());
		const pumpVelocity = decimalRounding(calculatePumpVelocity());
		updateDilution({ ...context, dilutionWaterCV, pumpVelocity });
	}, [context.dilutionWater]);

	React.useEffect(() => {
		calculateDilutionUnitChange();
	}, [context.dilutionUnit]);

	React.useEffect(() => {
		const dilutionWaterCV = decimalRounding(calculateDilutionWaterCV());
		updateDilution({ ...context, dilutionWaterCV });
	}, [context.pressureDrop, context.pressureDropUnit]);

	React.useEffect(() => {
		const pumpVelocity = decimalRounding(calculatePumpVelocity());
		updateDilution({ ...context, pumpVelocity });
	}, [context.pumpDiameter, context.pumpDiameterUnit]);

	const [
		unitConverterVisibility,
		updateUnitConverterVisibility,
	] = React.useState(false);

	return (
		<div className="container">
			<div className="values">
				<div className="watermark">
					<div className="watermark--image">

					</div>
					<div className="watermark--image offset">

					</div>
					<div className="watermark--image">

					</div>
				</div>
				<div id="unitconvertervalues">
					<div
						className="item--header"
						onClick={(_) =>
							updateUnitConverterVisibility(!unitConverterVisibility)
						}
					>
						<FontAwesomeIcon
							className={
								unitConverterVisibility
									? "faIcon"
									: "faIcon rotated"
							}
							icon={faAngleUp}
						></FontAwesomeIcon>
						Unit converter
					</div>

					<div
						className={
							unitConverterVisibility
								? "unitconverterwrap expanded"
								: "unitconverterwrap"
						}
					>
						<UnitConverter
							margin={"0 0 0.3em 0"}
							hideTitle={true}
						/>
					</div>
				</div>
				<div className="logo">
					<img className="logo--image" src="./assets/logo.png"></img>
				</div>
				<Units updateUnit={updateContextUnit} />
				<Production
					updateUnit={updateContextUnit}
					updateValue={updateContextValue}
				/>
				<BeforeDilution
					updateUnit={updateContextUnit}
					updateValue={updateContextValue}
				/>
				<AfterDilution
					updateUnit={updateContextUnit}
					updateValue={updateContextValue}
				/>
				<DilutionValues
					updateUnit={updateContextUnit}
					updateValue={updateContextValue}
				/>
				<div className="values--controls">
					<div
						className="values--controls--button"
						onClick={(e) => clearForm()}
					>
						Reset form
					</div>
					<div className="values--controls--print"
						onClick={e => window.print()}>
						Download as PDF
					</div>
				</div>
				<div className="printfooter">
					<div className="printfooter--text">
						Measurement Advisor
					</div>
				</div>
			</div>
			<div className="image">
				<ImageWrapper />
			</div>
		</div>
	);
};
