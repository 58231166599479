import * as React from "react";
// @ts-ignore No typings exist for this.
import Canvas from "react-responsive-canvas";
import { VelocityUnit } from "../Constants";
import { useConsistencyState } from "./ConsistencyContext";
import { PulpData } from "./PulpData";

type CanvasProps = {
	showError?: (state: boolean) => void;
};
let canvasRef: HTMLCanvasElement | undefined = undefined;

/**
 * Wrapper for react-responsive-canvas to handle the logic
 * of drawing areas and the grid.
 *
 * @param props Relevant values to draw with.
 */
export const ResponsiveCanvas: React.FC<CanvasProps> = ({showError}) => {
	const context = useConsistencyState();
	const pulp = context.pulpType;
	const sensor = context.sensorType;
	const vMin = context.velocityMin === "" ? 0 : context.velocityMin;
	const vMax = context.velocityMax === "" ? 0 : context.velocityMax;
	const cMin = context.consistencyMin === "" ? 0 : context.consistencyMin;
	const cMax = context.consistencyMax === "" ? 0 : context.consistencyMax;
	const velocityType = context.velocityUnit;

	// Valmet green for the graph sensor area.
	const sensorColor = "#50b948";
	// Blue "overlay" for the calculated velocity area.
	const velocityColor = "#008abab3";
	const
	scale_x = Math.ceil( PulpData.maxX(pulp, sensor)) + 1,
	scale_y = Math.ceil( PulpData.maxY(pulp, sensor)) + 1,
	paddingTop = 40,
	paddingBottom = 40,
	paddingLeft = 50,
	paddingRight = 50,
	titleX = VelocityUnit[velocityType],
	titleY = "%Cs",
	yval = 1,
	lineWidth = 1.0;

	let velocityMultiplier: number = 1;
	if (velocityType === "ft") {
		velocityMultiplier = 3.275;
	}
	const xval = velocityMultiplier;
	/**
	 * Draws the grid and axes on the canvas.
	 */
	const drawBase = () => {
		if (!canvasRef) {
			return;
		}
		let canvasContext = canvasRef.getContext("2d")!;

		canvasContext.strokeStyle = "black";
		canvasContext.lineWidth = lineWidth;
		// Draw vertical lines.
		let vdelta = (canvasRef.width - paddingLeft - paddingRight) / scale_x;

		for (let i = 0; i <= scale_x; i++) {
			let p = i * vdelta + paddingLeft;
			canvasContext.beginPath();
			canvasContext.moveTo(p, paddingTop);
			canvasContext.lineTo(p, canvasRef.height - paddingBottom);
			canvasContext.stroke();
		}

		// Draw horizontal lines.
		let hdelta = (canvasRef.height - paddingTop - paddingBottom) / scale_y;
		for (let i = 0; i <= scale_y; i++) {
			let p = i * hdelta + paddingTop;
			canvasContext.beginPath();
			canvasContext.moveTo(paddingLeft, p);
			canvasContext.lineTo(canvasRef.width - paddingRight, p);
			canvasContext.stroke();
		}

		// X & Y Axis title.
		canvasContext.font = "14px Arial";
		canvasContext.fillStyle = "black";
		canvasContext.fillText(titleX, canvasRef.width / 2 - 10, canvasRef.height - 5);
		canvasContext.fillText(titleY, 10, 25);

		// Axis values
		// x-axis
		let xdelta = (canvasRef.width - paddingLeft - paddingRight) / scale_x;
		let val = 0.0;
		for (let i = 0; i <= scale_x; i++) {
			let p = i * xdelta + paddingLeft;
			if (scale_x <= 10 || (scale_x > 10 && i % 2 === 0)) {
				canvasContext.fillText(val.toFixed(1).toString(), p - 8, canvasRef.height - 25);
			}
			val += xval;
		}
		// y-axis
		let ydelta = (canvasRef.height - paddingTop - paddingBottom) / scale_y;
		val = yval;
		for (let i = 1; i <= scale_y; i++) {
			let p = canvasRef.height - (i * ydelta + paddingTop);
			if (scale_y <= 10 || (scale_y > 10 && i % 2 === 0)) {
				canvasContext.fillText(val.toFixed(1).toString(), 20, p);
			}
			val += yval;
		}
	};

	/**
	 * Returns the canvas origin point coordinates.
	 */
	const origin = () => {
		return [paddingLeft, canvasRef!.height - paddingBottom];
	};

	/**
	 * Translates the given coordinates to correctly match on canvas.
	 *
	 * @param point Coordinates to translate.
	 */
	const translateCoordinates = (point: [number, number]) => {
		var delta_x = (canvasRef!.width - paddingLeft - paddingRight) / scale_x;
		var delta_y = (canvasRef!.height - paddingTop - paddingBottom) / scale_y;
		var o = origin();
		return [o[0] + (point[0] * delta_x), o[1] - (point[1] * delta_y)];
	};

	/**
	 * Function to draw the green "sensor" area to the canvas.
	 */
	const fillArea = () => {
		let color = sensorColor;
		let points = PulpData.getSensorPath(pulp, sensor, vMin, vMax);
		// draws lines to points and fills the area
		if (!points || points.length < 2 || !canvasRef) {
			return;
		}
		let canvasContext = canvasRef.getContext("2d")!;
		canvasContext.strokeStyle = color;
		canvasContext.fillStyle = color;
		canvasContext.beginPath();
		for (let i = 0; i < points.length; i++) {
			let realPoints = translateCoordinates(points[i]);
			canvasContext.lineTo(realPoints[0], realPoints[1]);
			canvasContext.stroke();
		}
		canvasContext.closePath();
		canvasContext.fill();
	};

	/**
	 * Returns the 'context.getImageData' data portion of the one pixel data.
	 *
	 * @param point point in the coord-system,translated to real canvas points.
	 */
	const getPixelData = (point: [number, number]) => {
		var rPoint = translateCoordinates(point);
		return canvasRef!.getContext("2d")!.getImageData(rPoint[0], rPoint[1], 1, 1).data;
	};

	/**
	 * Pixel color matching for whether values are in range.
	 * As sensor is now Valmet green the color we want to look for is
	 * green 185 on index 1 of the pixel data of RGBA;
	 *
	 * Functionality copied over from original. No idea if there is a better
	 * way to handle canvas stuff.
	 *
	 * @param points Point to check.
	 */
	const valuesOutOfRange = (points: number[][]) => {
		const greenVal = 185;
		let outOfRange = false;
		let p1 = getPixelData([points[0][0], points[0][1]]);
		if (p1[1] !== greenVal) {
			outOfRange = true;
		}
		var p2 = getPixelData([points[1][0], points[0][1]]);
		if (p2[1] !== greenVal) {
			outOfRange = true;
		}
		var p3 = getPixelData([points[0][0], points[1][1]]);
		if (p3[1] !== greenVal) {
			outOfRange = true;
		}
		var p4 = getPixelData([points[1][0], points[1][1]]);
		if (p4[1] !== greenVal) {
			outOfRange = true;
		}
		return outOfRange;
	};

	/**
	 * Draws the "flow" area on the canvas
	 * depending on consistency / flow / velocity values.
	 */
	const drawflowVelocityArea = () => {
		if (!canvasRef) {
			return;
		}
		let start: [number, number] = [vMin, cMin];
		let end: [number, number] = [vMax, cMax];
		if (velocityType === "ft") {
			start = [vMin / xval, cMin];
			end = [vMax / xval, cMax];
		}

		if (showError) {
			if (valuesOutOfRange([start, end]) && vMin > 0 && cMin > 0) {
				showError(true);
			} else {
				showError(false);
			}
		}
		let canvasContext = canvasRef.getContext("2d")!;
		let rPoint1 = translateCoordinates(start);
		let rPoint2 = translateCoordinates(end);
		canvasContext.strokeStyle = velocityColor;
		canvasContext.fillStyle = velocityColor;
		let x = rPoint1[0];
		let y = rPoint1[1];
		let width = rPoint2[0] - rPoint1[0];
		let height = rPoint2[1] - rPoint1[1];
		canvasContext.fillRect(x, y, width, height);
	};

	/**
	 * Clears the canvas of earlier renders.
	 */
	const clearCanvas = () => {
		if (canvasRef) {
			let canvasContext = canvasRef.getContext("2d")!;
			canvasContext.clearRect(0, 0, canvasRef.width, canvasRef.height);
		}
	};

	React.useEffect(() => {
		clearCanvas();
		drawBase();
		fillArea();
		drawflowVelocityArea();
	}, [pulp, sensor, vMin, vMax, cMin, cMax, velocityType]);

	return (
		<Canvas canvasRef={(el: HTMLCanvasElement) => {
			canvasRef = el;
			setTimeout(() => {
				drawBase();
				fillArea();
				drawflowVelocityArea(); }, 0);
		}}
		onResize={() => {
			drawBase();
			fillArea();
			drawflowVelocityArea();
		}}/>
	);
};
