import * as React from "react";
import { useConsistencyState, useUpdateConsistency } from "./ConsistencyContext";
import {
	diameterLimits,
	TRANSMITTERS,
	PulpNames,
	MATERIALS,
	FlowUnit,
	VelocityUnit,
	PipeType,
	allSENSORS,
	fiberPulpNames,
} from "../Constants";
import { OptionComponent } from "./OptionComponent";
import { OptSelect } from "./OptSelect";
import { PulpData } from "./PulpData";
import { ResponsiveCanvas } from "./ResponsiveCanvas";
import { Steps } from "./ConsistencyPage";
import { OptRadioStr } from "./OptRadioStr";
import { OptInputLabel } from "./OptInputLabel";

type CCalculationProps = {
	nextStep: React.Dispatch<React.SetStateAction<Steps>>;
};

/**
 * Main calculation visualization page.
 */
export const ConsistencyCalculation: React.FC<CCalculationProps> = ({nextStep}) => {
	const consistencyContext = useConsistencyState();
	const consistencyUpdate = useUpdateConsistency();

	const [rangeError, showRangeError] = React.useState(false);

	const pulpList = consistencyContext.pulpList;

	const pipeUnit = consistencyContext.pipeUnit;
	const pipeMaterial = consistencyContext.pipeType;
	const diameterValue = consistencyContext.pipeDiameter;
	const diameterUnit = consistencyContext.pipeUnit;

	const transmitterType = consistencyContext.transmitterType;
	const pulpType = consistencyContext.pulpType;
	const sensorType = consistencyContext.sensorType;

	const updateMaterialsList = (sensor: keyof typeof allSENSORS): {[key: string]: string } => {
		const materialsList = PulpData.getMaterialsList(pulpType, sensor);
		return materialsList;
	};

	const materialType = consistencyContext.sensorMaterial;
	let availableMaterials = updateMaterialsList(sensorType);

	const fMin = consistencyContext.flowMin;
	const fMax = consistencyContext.flowMax;
	const fUnit = consistencyContext.flowUnit;

	const vMin = consistencyContext.velocityMin;
	const vMax = consistencyContext.velocityMax;
	const vUnit = consistencyContext.velocityUnit;

	const cMin = consistencyContext.consistencyMin;
	const cMax = consistencyContext.consistencyMax;

	/**
	 * Calculates velocity based on changed flow value.
	 *
	 * @param newFlowValue value to calculate by.
	 */
	const convertFlow2Velocity = (
		newFlowValue: number,
		diameter: number,
		flowUnit: keyof typeof FlowUnit,
	) => {
		let flowk = PulpData.flowUnitMultiplier(flowUnit);
		let pipek = PulpData.pipeMultiplier(pipeUnit);
		let velok = PulpData.velocityUnitMultiplier(vUnit);
		let velo = flowk * 0.1 * newFlowValue /
			(Math.PI * Math.pow((pipek * diameter / 200), 2)) / velok;
		return parseFloat(velo.toFixed(1));
	};

	/**
	 * Calculates flow based on changed velocity value.
	 *
	 * @param newVelocityValue value to calculate by.
	 */
	const convertVelocity2Flow = (
		newVelocityValue: number,
		unit?: keyof typeof VelocityUnit,
	) => {
		let velocityUnit = unit ? unit : vUnit;
		let flowk = PulpData.flowUnitMultiplier(fUnit);
		let pipek = PulpData.pipeMultiplier(pipeUnit);
		let velok = PulpData.velocityUnitMultiplier(velocityUnit);
		const diameter = diameterValue === "" ? 100 : diameterValue;
		let flow = velok * 10 * newVelocityValue *
			Math.PI * (Math.pow((pipek * diameter / 200), 2) / flowk);
		return parseFloat(flow.toFixed(1));
	};

	/**
	 * A whole lot of change handlers for form options with some variations in handling.
	 */
	const pipeTypeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let input = event.target.value as (keyof typeof PipeType);
		let pulps: {[key: string]: string} = {};
		let sensor: keyof typeof allSENSORS = "RL";
		let pulp: keyof typeof PulpNames = "SWU";
		if (input === "f") {
			pulps = fiberPulpNames;
			sensor = "JL";
		} else if (input === "m") {
			pulps = PulpNames;
			sensor = PulpData.getRecommendedSensor(pulp);
		}
		if (pulps[pulpType]) {
			pulp = pulpType;
		}
		consistencyUpdate({
			...consistencyContext,
			pipeType: input,
			pulpList: pulps,
			pulpType: pulp,
			sensorType: sensor,
		});
	};

	const diameterChangeHandler = (input: string) => {
		let diameter: number | "" = "";
		if (!(input === "")) {
			diameter = parseFloat(input);
		}
		if (diameter !== "" && fMax !== "" && fMin !== "") {
			let velocityMax = convertFlow2Velocity(fMax, diameter, fUnit);
			let velocityMin = convertFlow2Velocity(fMin, diameter, fUnit);

			consistencyUpdate({
				...consistencyContext,
				pipeDiameter: diameter,
				velocityMax,
				velocityMin,
			});
		} else {
			consistencyUpdate({
				...consistencyContext,
				pipeDiameter: diameter,
			});
		}
	};

	const diameterUnitChange = (input: string) => {
		let diameter = diameterValue;
		if (input === "in") {
			if (diameter !== "") {
				diameter = diameter / 25.4;
			}
			consistencyUpdate({...consistencyContext, pipeDiameter: diameter, pipeUnit: input });
		} else if (input === "mm") {
			if (diameter !== "") {
				diameter = diameter * 25.4;
			}
			consistencyUpdate({...consistencyContext, pipeDiameter: diameter, pipeUnit: input });
		}
	};

	/**
	 * Contains check to keep the currently checked sensor valid for the new pulp type.
	 */
	const pulpTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		let value = event.target.value as (keyof typeof PulpNames);
		let sensor: keyof typeof allSENSORS = "RL";
		if (pipeMaterial === "m") {
			sensor = PulpData.getRecommendedSensor(value);
		} else if (pipeMaterial === "f") {
			sensor = "JL";
		}
		consistencyUpdate({...consistencyContext, pulpType: value, sensorType: sensor });
	};

	const sensorTypeChange = (input: keyof typeof allSENSORS) => {
		availableMaterials = updateMaterialsList(input);
		let defaultMaterial: keyof typeof MATERIALS = "aisip";
		// If this is not available, get the first from the list.
		if (!availableMaterials["aisip"]) {
			defaultMaterial = Object.keys(availableMaterials)[0] as keyof typeof MATERIALS;
		}
		consistencyUpdate({...consistencyContext, sensorType: input, sensorMaterial: defaultMaterial });
	};

	const sensorMaterialChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		let value = event.target.value as (keyof typeof MATERIALS);
		consistencyUpdate({...consistencyContext, sensorMaterial: value });
	};

	const flowMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let flowMin: number | "" = "";
		if (!(event.target.value === "")) {
			flowMin = parseFloat(event.target.value);
		}
		consistencyUpdate({...consistencyContext, flowMin });
	};

	/**
	 * Numeric input fields have 2 handlers, Change for just inputting numbers which updates it.
	 * Blur for when the field loses focus so input is "finished".
	 *
	 * Blur handlers have logic to calculate other relevant values by their new value.
	 */
	const flowMinBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		let flowMin = 0;
		if (!(event.target.value === "")) {
			flowMin = parseFloat(event.target.value);
		}
		let flowMax = fMax;
		let velocityMin = 0;
		if (diameterValue !== "") {
			velocityMin = convertFlow2Velocity(flowMin, diameterValue, fUnit);
		}
		let velocityMax = vMax;
		if (flowMin >= flowMax) {
			flowMax = flowMin + 0.1;
		}
		if (velocityMin >= velocityMax) {
			velocityMax = velocityMin + 0.1;
		}
		consistencyUpdate({
			...consistencyContext,
			flowMin,
			flowMax,
			velocityMin,
			velocityMax,
		});
	};

	const flowMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let flowMax: number | "" = "";
		if (!(event.target.value === "")) {
			flowMax = parseFloat(event.target.value);
		}
		consistencyUpdate({...consistencyContext, flowMax });
	};

	const flowMaxBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		let flowMax: number = 0;
		if (!(event.target.value === "")) {
			flowMax = parseFloat(event.target.value);
		}
		let velocityMax: number = 0;
		if (diameterValue !== "") {
			velocityMax = convertFlow2Velocity(flowMax, diameterValue, fUnit);
		}
		let velocityMin = vMin;
		let flowMin = fMin;
		if (flowMax <= flowMin) {
			flowMin = flowMax - 0.1;
		}
		if (velocityMax <= velocityMin) {
			velocityMin = velocityMax - 0.1;
		}
		consistencyUpdate({
			...consistencyContext,
			flowMax,
			flowMin,
			velocityMax,
			velocityMin,
		});
	};

	const flowUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		let unit = e.target.value as (keyof typeof FlowUnit);
		let velocityMax: number | "" = "";
		if (fMax !== "" && diameterValue !== "") {
			velocityMax = convertFlow2Velocity(fMax, diameterValue, unit);

		}
		let velocityMin: number | "" = "";
		if (fMin !== "" && diameterValue !== "") {
			velocityMin = convertFlow2Velocity(fMin, diameterValue, unit);
		}
		consistencyUpdate({
			...consistencyContext,
			flowUnit: unit,
			velocityMin,
			velocityMax,
		});
	};

	const velocityMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let velocityMin: number | "" = "";
		if (!(event.target.value === "")) {
			velocityMin = parseFloat(event.target.value);
		}
		consistencyUpdate({...consistencyContext, velocityMin });
	};

	const velocityMinBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		let velocityMin: number = 0;
		if (!(event.target.value === "")) {
			velocityMin = parseFloat(event.target.value);
		}
		let velocityMax = vMax;
		let flowMin = convertVelocity2Flow(velocityMin);
		let flowMax = fMax;
		if (velocityMin >= velocityMax) {
			velocityMax = velocityMin + 0.1;
		}
		if (flowMin >= flowMax) {
			flowMax = flowMin + 0.1;
		}
		consistencyUpdate({
			...consistencyContext,
			flowMax,
			flowMin,
			velocityMax,
			velocityMin,
		});
	};

	const velocityMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let velocityMax: number | "" = "";
		if (!(event.target.value === "")) {
			velocityMax = parseFloat(event.target.value);
		}
		consistencyUpdate({...consistencyContext, velocityMax });
	};

	const velocityMaxBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		let velocityMax: number = 0;
		if (!isNaN(parseFloat(event.target.value))) {
			velocityMax = parseFloat(event.target.value);
		}
		let velocityMin = vMin;
		let flowMax = convertVelocity2Flow(velocityMax);
		let flowMin = fMin;
		if (velocityMax <= velocityMin) {
			velocityMin = velocityMax - 0.1;
		}
		if (flowMax <= flowMin) {
			flowMin = flowMax - 0.1;
		}
		consistencyUpdate({
			...consistencyContext,
			flowMin,
			flowMax,
			velocityMin,
			velocityMax,
		});
	};

	const velocityUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const unit = e.target.value as (keyof typeof VelocityUnit);
		let flowMax: number | "" = vMax === "" ? "" : convertVelocity2Flow(vMax, unit);
		let flowMin: number | "" = vMin === "" ? "" : convertVelocity2Flow(vMin, unit);
		consistencyUpdate({
			...consistencyContext,
			velocityUnit: unit,
			flowMax,
			flowMin,
		});
	};

	const consistencyMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let consistencyMin: number | "" = "";
		if (!(event.target.value === "")) {
			consistencyMin = parseFloat(event.target.value);
		}
		consistencyUpdate({...consistencyContext, consistencyMin });
	};

	const consistencyMinBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		let consistencyMin: number = 0;
		if (!isNaN(parseFloat(event.target.value))) {
			consistencyMin = parseFloat(event.target.value);
		}
		let consistencyMax = cMax;
		if (consistencyMin >= consistencyMax) {
			consistencyMax = consistencyMin + 0.1;
		}
		consistencyUpdate({
			...consistencyContext,
			consistencyMin,
			consistencyMax,
		});
	};

	const consistencyMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let consistencyMax: number | "" = "";
		if (!(event.target.value === "")) {
			consistencyMax = parseFloat(event.target.value);
		}
		consistencyUpdate({...consistencyContext, consistencyMax });
	};

	const consistencyMaxBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		let consistencyMax: number = 0;
		if (!isNaN(parseFloat(event.target.value))) {
			consistencyMax = parseFloat(event.target.value);
		}
		let consistencyMin = cMin;
		if (consistencyMax <= consistencyMin) {
			consistencyMin = consistencyMax - 0.1;
		}
		consistencyUpdate({
			...consistencyContext,
			consistencyMax,
			consistencyMin,
		});
	};

	return (
		<div id="ccalculation">
			<div className="graph">
				<div className="graph--title">
					Calculation Graph
				</div>
				<div className="graph--area">
					{
						<ResponsiveCanvas showError={showRangeError}/>
					}
					{
						rangeError &&
						<div className="graph--area--error">
							Values out of range
						</div>
					}
				</div>
			</div>
			<div className="wrapper">
				<div className="options">
					<div className="options--title">
						<div className="text">
							Calculation Options
						</div>
						<div className="text">
							{TRANSMITTERS[transmitterType]}
						</div>
					</div>
					<OptionComponent header={"Pipe material"}>
						{
							Object.keys(PipeType).map((key: keyof typeof PipeType) => {
								return (
									<section key={key}>
										<OptRadioStr callback={pipeTypeChangeHandler} current={key}
											initialSelection={pipeMaterial} name={PipeType[key].name}/>
										<OptInputLabel name={PipeType[key].name} text={PipeType[key].text}/>
									</section>
								);
							})
						}
					</OptionComponent>
					<OptionComponent header={"Pipe Diameter (inner)"}>
						<div className="options--pipe--diameter--wrap">
							<div className="options--pipe--diameter--wrap--range">
								<input type="range" className="range"
									min={diameterUnit === "mm" ? diameterLimits.min_mm : diameterLimits.min_in}
									max={diameterUnit === "mm" ? diameterLimits.max_mm : diameterLimits.max_in}
									value={diameterValue} onChange={e => diameterChangeHandler(e.target.value)}/>
							</div>
							<section>
								<input type="number" value={diameterValue}
									onChange={e => diameterChangeHandler(e.target.value)}/>
								<input type="radio" name="diameter" value="mm"
									id="mm" defaultChecked={diameterUnit === "mm"}
									onChange={e => diameterUnitChange(e.target.value)}/>
								<label htmlFor="mm">
									mm
								</label>
								<input type="radio" name="diameter" value="in"
									id="inch" defaultChecked={diameterUnit === "in"}
									onChange={e => diameterUnitChange(e.target.value)}/>
								<label htmlFor="inch">
									inch
								</label>
							</section>
						</div>
					</OptionComponent>
					<OptionComponent header="Pulp type">
						<OptSelect callback={pulpTypeChange} data={pulpList} initial={pulpType}/>
					</OptionComponent>
					<div className="options--sensor">
						<div className="options--sensor--type">
							<div className="options--sensor--type--title">
								Sensor Type
							</div>
							<div className="options--sensor--type--wrap">
								{
									PulpData.getPulpSensors(pulpType, pipeMaterial).map(sensor => {
										return (
											<button className={"options--sensor--type--wrap--item"
												+ (sensorType === sensor ? " active" : "")}
												onClick={e => sensorTypeChange(sensor)}
												key={sensor}>
												{sensor}
											</button>
										);
									})
								}
							</div>
						</div>
					</div>
					<OptionComponent header={"Sensor material"}>
						<OptSelect callback={sensorMaterialChange} data={availableMaterials} initial={materialType}/>
					</OptionComponent>
					<div className="options--values">
						<div className="options--values--flow">
							<div className="options--values--flow--title">
								Flow
							</div>
							<div className="options--values--flow--fields">
								<input type="number" step="0.1" placeholder="min"
									value={fMin} onBlur={flowMinBlur} onChange={flowMinChange}/>
								<input type="number" step="0.1" placeholder="max"
									value={fMax} onBlur={flowMaxBlur} onChange={flowMaxChange}/>
								<select onChange={flowUnitChange} value={fUnit}>
									{
										Object.keys(FlowUnit).map((key: keyof typeof FlowUnit) => {
											return (
												<option value={key} key={key}>
													{FlowUnit[key]}
												</option>
											);
										})
									}
								</select>
							</div>
						</div>
						<div className="options--values--velocity">
							<div className="options--values--velocity--title">
								Velocity
							</div>
							<div className="options--values--velocity--fields">
								<input type="number" step="0.1" placeholder="min"
									value={vMin} onBlur={velocityMinBlur} onChange={velocityMinChange}/>
								<input type="number" step="0.1" placeholder="max"
									value={vMax} onBlur={velocityMaxBlur} onChange={velocityMaxChange}/>
								<select onChange={velocityUnitChange} value={vUnit}>
									{
										Object.keys(VelocityUnit).map((key: keyof typeof VelocityUnit) => {
											return (
												<option value={key} key={key}>
													{VelocityUnit[key]}
												</option>
											);
										})
									}
								</select>
							</div>
						</div>
						<div className="options--values--consistency">
							<div className="options--values--consistency--title">
								Consistency % Cs
							</div>
							<div className="options--values--consistency--fields">
								<input type="number" step="0.1" placeholder="min"
									value={cMin} onBlur={consistencyMinBlur} onChange={consistencyMinChange}/>
								<input type="number" step="0.1" placeholder="max"
									value={cMax} onBlur={consistencyMaxBlur} onChange={consistencyMaxChange}/>
								<div></div>
							</div>
						</div>
					</div>
					<div className="options--button">
						<button onClick={e => nextStep(Steps.Requirements)}>
							Next: Pipe Requirements
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
