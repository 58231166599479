import * as React from "react";
import { Page } from "../Constants";
import { ItemsContainer } from "./ItemsContainer";

/**
 * Main layout for dilution calculation page.
 */
export const DilutionPage: React.FC<{}> = () => {
	return (
		<div className="dilutionpage">
			<div className="dilutionpage--header">
				<div className="backbutton">
					<div
						className="backbutton--icon"
						onClick={(e) => {
							window.location.pathname = Page.LandingPage;
						}}
					/>
				</div>
				<div className="dilutionpage--header--title">
					Dilution calculator
				</div>
			</div>
			<div className="dilutionpage--content">
				<ItemsContainer />
			</div>
		</div>
	);
};
