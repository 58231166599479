export const enum Page {
	LandingPage = "/",
	HelpPage = "/help",
	ConsistencyPage = "/consistencypage",
	GeneralDataPage = "/general",
	DilutionPage = "/dilutionpage",
	MaterialsPage = "/materialspage",
	TutorialsPage = "/tutorialspage",
	UnitConverterPage = "/unitconverterpage",
}

export const TRANSMITTERS = {
	VSP: "Valmet SP",
} as const;

export const fiberSENSORS = {
	JL: "JL",
} as const;

export const SENSORS = {
	LL: "LL",
	LS: "LS",
	UL: "UL",
	GL: "GL",
	RL: "RL",
	HL: "HL",
	WS: "WS",
} as const;

export const allSENSORS = {
	LL: "LL",
	LS: "LS",
	UL: "UL",
	GL: "GL",
	RL: "RL",
	HL: "HL",
	WS: "WS",
	JL: "JL",
} as const;

export const MATERIALS = {
	aisip: "AISI 316L, polished",
	aisiu: "AISI 316L, unpolished",
	smop: "254 SMO, Polished",
	smou: "254 SMO, Unpolished",
} as const;

export const generaldefaults: GeneralData = {
	name: "",
	mill: "",
	application: "",
	tag: "",
	notes: "",
};

export type GeneralData = {
	name: string;
	mill: string;
	application: string;
	tag: string;
	notes: string;
};

export const fiberPulpNames = {
	SWU: "SWU Softwood unbleached",
	SWB: "SWB Softwood bleached",
	HWU: "HWU short-fibered, unbleached",
	HWB: "HWB short-fibered, bleached",
} as const;

export const PulpNames = {
	SWU: "SWU Softwood unbleached",
	SWB: "SWB Softwood bleached",
	HWU: "HWU short-fibered, unbleached",
	HWB: "HWB short-fibered, bleached",
	GW: "GW Groundwood",
	RMPL: "RMPL/TMPL RMP/TMP CSF<200mL (SR>52)",
	RMPH: "RMPH/TMPH RMP/TMP CSF>200mL (SR<52)",
	ROCCU: "ROCCU Recycled fiber, unscreened (OCC)",
	RCFU: "RCFU Recycled fiber, unscreened",
	ROCCS: "ROCCS Recycled fiber, screened (OCC)",
	RCFS: "RCFS Recycled fiber, screened",
	CTMP: "CTMP Chemithermo-mechanical pulp",
} as const;

export const Mounting = {
	A: "A",
	B1: "B1",
	B2: "B2",
	C: "C",
	D: "D",
};

export const FlowUnit = {
	lmin: "l/min",
	ls: "l/s",
	usgpm: "usgpm",
	m3h: "m3/h",
} as const;

export const VelocityUnit = {
	m: "m/s",
	ft: "ft/s",
} as const;

export const PipeType = {
	m: {
		name: "metallic",
		text: "Metallic < 0.4 inch (< 10 mm)",
	},
	f: {
		name: "fiber",
		text: "Fiber Glass > 0.4 inch (> 10 mm)",
	},
} as const;

export const PipeUnit = {
	mm: "mm",
	in: "inch",
} as const;

export type ConsistencyData = {
	pipeType: keyof typeof PipeType;
	pipeDiameter: number | "";
	pipeUnit: keyof typeof PipeUnit;
	transmitterType: keyof typeof TRANSMITTERS;
	pulpType: keyof typeof PulpNames;
	pulpList: { [key: string]: string };
	sensorType: keyof typeof allSENSORS;
	flowMin: number | "";
	flowMax: number | "";
	flowUnit: keyof typeof FlowUnit;
	velocityMin: number | "";
	velocityMax: number | "";
	velocityUnit: keyof typeof VelocityUnit;
	consistencyMin: number | "";
	consistencyMax: number | "";
	sensorMaterial: keyof typeof MATERIALS;
	mounting: keyof typeof Mounting;
	mountingSelection: MountingSelection;
};

export type MountingSelection = {
	[key in keyof typeof Mounting]: {
		selected: boolean;
		notes: string;
	};
};

export const consistencydefaults: ConsistencyData = {
	consistencyMax: 0,
	consistencyMin: 0,
	flowMax: 0,
	flowMin: 0,
	flowUnit: "ls",
	mounting: "A",
	mountingSelection: {
		A: { selected: true, notes: "" },
		B1: { selected: false, notes: "" },
		B2: { selected: false, notes: "" },
		C: { selected: false, notes: "" },
		D: { selected: false, notes: "" },
	},
	pipeDiameter: 100,
	pipeType: "m",
	pipeUnit: "mm",
	pulpType: "SWU",
	pulpList: PulpNames,
	sensorMaterial: "aisiu",
	sensorType: "RL",
	transmitterType: "VSP",
	velocityMax: 0,
	velocityMin: 0,
	velocityUnit: "m",
};

export const ProductionUnit = {
	metric: "Metric tonnes",
	us: "Short tons(US)",
	uk: "Long tons(UK)",
} as const;

export const PressureUnit = {
	bar: "bar",
	psi: "psi",
	kpa: "kPa",
} as const;

export type DilutionData = {
	pipeDiameter: number | "";
	pipeDiameterUnit: keyof typeof PipeUnit;
	productionPerDay: number | "";
	productionUnit: keyof typeof ProductionUnit;
	beforeConsistency: number | "";
	beforeFlow: number | "";
	flowUnit: keyof typeof FlowUnit;
	beforeVelocity: number | "";
	velocityUnit: keyof typeof VelocityUnit;
	afterConsistency: number | "";
	afterFlow: number | "";
	afterVelocity: number | "";
	dilutionWater: number | "";
	dilutionUnit: keyof typeof FlowUnit;
	dilutionWaterCV: number | "";
	pressureDrop: number | "";
	pressureDropUnit: keyof typeof PressureUnit;
	pumpDiameter: number | "";
	pumpDiameterUnit: keyof typeof PipeUnit;
	pumpVelocity: number | "";
	pumpVelocityUnit: keyof typeof VelocityUnit;
};

export const dilutionDefaults: DilutionData = {
	afterConsistency: "",
	afterFlow: "",
	afterVelocity: "",
	beforeConsistency: "",
	beforeFlow: "",
	flowUnit: "lmin",
	beforeVelocity: "",
	velocityUnit: "m",
	dilutionUnit: "lmin",
	dilutionWater: "",
	dilutionWaterCV: "",
	pipeDiameter: "",
	pipeDiameterUnit: "mm",
	pressureDrop: "",
	pressureDropUnit: "bar",
	productionPerDay: "",
	productionUnit: "metric",
	pumpDiameter: "",
	pumpDiameterUnit: "mm",
	pumpVelocity: "",
	pumpVelocityUnit: "m",
};

export const materialMeasures = {
	in: 0.4,
	mm: 10,
};

export const diameterLimits = {
	min_mm: 50,
	max_mm: 1500,
	min_in: 1.968503937,
	max_in: 59.05511811,
};

export const mountingOptions: {
	key: keyof typeof Mounting;
	name: string;
	text: string;
}[] = [
	{
		key: "A",
		name: "Vertical (A)",
		text:
			"This is the preferred location in terms of the control loop's lag time.",
	},
	{
		key: "B1",
		name: "Horizontal side (B1)",
		text:
			"In this alternative you install the transmitter on the side of the line to prevent \
			possible air from disturbing the measurement.",
	},
	{
		key: "B2",
		name: "Horizontal top (B2)",
		text:
			"This method may be considered if the straight length of horizontal pipe is too short \
			for alternative B1. Installation on the top side of the pipe always requires careful \
			consideration, because a substantial buildup of air in the pipe may affect the measurement accuracy.",
	},
	{
		key: "C",
		name: "Horizontal side (C)",
		text:
			"You may consider this option when horizontal and vertical sections before the \
			transmitter are too short. The double bend before the measurement point will cause \
			problems when L2 < L1. The whirling flow produced by the double bend has to be \
			eliminated with FLOW TR. In addition, you have consider the control loop's lag time, \
			which is considerably longer in this alternative than in alternative A.",
	},
	{
		key: "D",
		name: "Vertical (D)",
		text:
			"In this method you install the transmitter on the side of pipe bend's outer \
			curve. Measurement lag is considerably longer than in alternative A.",
	},
];
