import * as React from "react";
import { useDilutionState } from "./DilutionContext";
import { ItemProps } from "./ItemsContainer";
import { Value } from "./Value";

/**
 * Production values component.
 */
export const Production: React.FC<ItemProps> = ({updateValue, margin}) => {

	const context = useDilutionState();

	const pipeDiameter = context.pipeDiameter;
	const pipeKey = "pipeDiameter";
	const pipeHeader = "Process pipe diameter";

	const production = context.productionPerDay;
	const productionKey = "productionPerDay";
	const productionHeader = "Production per day";

	return (
		<div className="item" style={{ margin }}>
			<div className="item--header">
				Process & Production
			</div>
			<div className="item--values">
				<Value value={pipeDiameter} header={pipeHeader}
					update={updateValue} valueKey={pipeKey}>
				</Value>
				<Value value={production} header={productionHeader}
					update={updateValue} valueKey={productionKey}>
				</Value>
			</div>
		</div>
	);
};
