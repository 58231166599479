import * as React from "react";
import { Page } from "./Constants";

/**
 * Functional component for site top navigation controls.
 */
export const Navigation: React.FC<{}> = () => {

	// State to display the mobile menu or not by clicking the menu icon.
	// CSS handles showing / hiding the menu icon and the original menu.
	const [menuStatus, setMenuStatus] = React.useState(false);

	return (
		<div className="navigation-main">
			<div className="navigation-main-logo">
				<a href="https://www.valmet.com">
					<img src={"./assets/signature.png"}/>
				</a>
			</div>
			<div className="navigation-main-menu">
				<div className="navigation-main-menu-item"
					onClick={() => window.location.pathname = Page.LandingPage}>
					Main menu
				</div>
				<div className="navigation-main-menu-item"
					onClick={() => window.location.pathname = Page.HelpPage}>
					Help
				</div>
				<div className="navigation-main-menu-item"
					onClick={() => window.open(
						"https://www.valmet.com/about-us/contact-us/contact-form/",
					)}>
					Contact us
				</div>
				<div className="navigation-main-menu-item"
					onClick={() => window.open(
						"https://www.valmet.com/automation/analyzers-measurements/consistency/",
					)}>
					Valmet Consistency
				</div>
			</div>
			<div className="navigation-main-menu--mobile">
				{
					menuStatus &&
					<div className="navigation-main-menu--mobile-wrap">
						<div className="navigation-main-menu--mobile-wrap-item"
							onClick={() => {
								setMenuStatus(false);
								window.location.pathname = Page.LandingPage;
							}}>
							Main menu
						</div>
						<div className="navigation-main-menu--mobile-wrap-item"
							onClick={() => {
								setMenuStatus(false);
								window.location.pathname = Page.HelpPage;
							}}>
							Help
						</div>
					</div>
				}
			</div>
			<div className="navigation-main-menu--icon" onClick={() => setMenuStatus(!menuStatus)}>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};
