import * as React from "react";
import { Page } from "../Constants";
import { DocumentItem } from "./DocumentItem";

export const MaterialsPage: React.FC<{}> = () => {

	/**
	 * List of downloadable material document file names.
	 */
	const names = [
		"Valmet Blade Consistency Measurement",
		"Valmet Microwave Consistency Measurement Brochure",
		"Valmet Optical Consistency Measurement",
		"Valmet Optical Low Consistency Transmitter",
		"Valmet Rotating Consistency Transmitter",
	];

	const rows: string[][] = [];
	let singleRow: string[] = [];
	if (names.length > 0) {
		names.forEach((name, index) => {
			if (index !== 0 && index % 4 === 0) {
				rows.push(singleRow);
				singleRow = [];
			}
			singleRow.push(name);
		});
		rows.push(singleRow);
	}

	return (
		<div className="materialspage">
			<div id="materialslayout">
				<div className="topbar">
					<div className="topbar--back">
						<div className="topbar--back--icon" onClick={e => {
						window.location.pathname = Page.LandingPage;
					}}/>
					</div>
					<div className="topbar--title">
						Download Materials
					</div>
				</div>
				<div className="docs">
					{
						rows.map((row, n) => {
							return (
								<div className="row" key={n}>
									{
										row.map(item => {
											return (
												<DocumentItem name={item} key={item}/>
											);
										})
									}
								</div>
							);
						})
					}
					{
						rows.length === 0 &&
						<div className="nodocs">
							No documents available yet.
						</div>
					}
				</div>
			</div>
		</div>
	);
};
