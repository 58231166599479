import * as React from "react";
import { DilutionData, FlowUnit, PipeUnit, ProductionUnit, VelocityUnit } from "../Constants";
import { useDilutionState } from "./DilutionContext";
import { UnitSelect } from "./UnitSelect";

export type UnitProps = {
	updateUnit: (event: React.ChangeEvent<HTMLSelectElement>, key: keyof DilutionData) => void;
};

export const Units: React.FC<UnitProps> = ({ updateUnit }) => {

	const context = useDilutionState();

	const pipeUnit = context.pipeDiameterUnit;
	const pipeUnitKey = "pipeDiameterUnit";
	const pipeHeader = "Pipe diameter";

	const productionUnit = context.productionUnit;
	const productionUnitKey = "productionUnit";
	const productionHeader = "Production/day";

	const flowUnit = context.flowUnit;
	const flowUnitKey = "flowUnit";
	const flowHeader = "Flow rate pulp";

	const velocityUnit = context.velocityUnit;
	const velocityUnitKey = "velocityUnit";
	const velocityHeader = "Velocity";

	const waterUnit = context.dilutionUnit;
	const waterUnitKey = "dilutionUnit";
	const waterHeader = "Dilution water";

	return (
		<div className="item">
			<div className="item--header">
				Units of measurement
			</div>
			<div className="item--units">
				<UnitSelect updateUnit={updateUnit} contextKey={pipeUnitKey}
					currentUnit={pipeUnit} valueKeys={PipeUnit} header={pipeHeader}/>
				<UnitSelect updateUnit={updateUnit} contextKey={flowUnitKey}
					currentUnit={flowUnit} valueKeys={FlowUnit} header={flowHeader}/>
				<UnitSelect updateUnit={updateUnit} contextKey={velocityUnitKey}
					currentUnit={velocityUnit} valueKeys={VelocityUnit} header={velocityHeader}/>
				<UnitSelect updateUnit={updateUnit} contextKey={waterUnitKey}
					currentUnit={waterUnit} valueKeys={FlowUnit} header={waterHeader}/>
				<UnitSelect updateUnit={updateUnit} contextKey={productionUnitKey}
					currentUnit={productionUnit} valueKeys={ProductionUnit} header={productionHeader}/>
				<div className="unitwrap"/>
			</div>
		</div>
	);
};
