import * as React from "react";
import { Page } from "./Constants";
import { useGeneralState, useUpdateGeneral } from "./GeneralContext";

type SpecsProps = {
	appCallback?: (param: boolean) => void;
	callbackParameter?: boolean;
};

/**
 * Component to handle the general data input for name, mill, etc.
 * Basic small form with a continue button.
 */
export const GeneralDataForm: React.FC<SpecsProps> = (props) => {

	const generalContext = useGeneralState();
	const generalUpdate = useUpdateGeneral();
	const name = generalContext.name;
	const mill = generalContext.mill;
	const application = generalContext.application;
	const tag = generalContext.tag;
	const notes = generalContext.notes;
	const submitHandler = () => {
		if (props.callbackParameter && props.appCallback) {
			props.appCallback(props.callbackParameter);
		} else {
			window.location.pathname = Page.ConsistencyPage;
		}
	};

	return (
		<div className="generaldataform">
			<div className="calculationspecs">
				<h3 className="calculationspecs--header">
					Calculation Name & Details
				</h3>
				<div className="calculationspecs--help">
					Type in the calculation details.
					<br/>
					You can fill this information later and continue straight to calculation as well.
				</div>
				<div className="calculationspecs--form">
					<div className="calculationspecs--form--name">
						<div>Calculation Name</div>
						<input value={name}
							onChange={e => generalUpdate({...generalContext, name: e.target.value })}/>
					</div>
					<div className="calculationspecs--form--mill">
						<div>Mill</div>
						<input value={mill}
							onChange={e => generalUpdate({...generalContext, mill: e.target.value })}/>
					</div>
					<div className="calculationspecs--form--app">
						<div>Application</div>
						<input value={application}
							onChange={e => generalUpdate({...generalContext, application: e.target.value })}/>
					</div>
					<div className="calculationspecs--form--tag">
						<div>Tag Number</div>
						<input value={tag}
							onChange={e => generalUpdate({...generalContext, tag: e.target.value })}/>
					</div>
					<div className="calculationspecs--form--notes">
						<div>User Notes</div>
						<textarea value={notes} rows={4}
							onChange={e => generalUpdate({...generalContext, notes: e.target.value })}/>
					</div>
				</div>
				<div className="calculationspecs--submit">
					<button onClick={e => submitHandler()}>
						Continue to calculations
					</button>
				</div>
			</div>
		</div>
	);
};
