import * as React from "react";
import { PressureUnit } from "../Constants";
import { useDilutionState } from "./DilutionContext";
import { ItemProps } from "./ItemsContainer";
import { Value } from "./Value";

export const DilutionValues: React.FC<ItemProps> = ({updateValue, updateUnit, margin}) => {

	const context = useDilutionState();

	const waterHeader = "Dilution water need";
	const water = context.dilutionWater;
	const waterKey = "dilutionWater";
	const waterCV = context.dilutionWaterCV;
	const waterCVHeader = "CV";
	const waterCVKey = "dilutionWaterCV";

	const pressureHeader = "Min. pressure drop in dilution valve";
	const pressure = context.pressureDrop;
	const pressureKey = "pressureDrop";
	const pressureUnitKey = "pressureDropUnit";

	const pumpDiameterHeader = "Dilution pipe diameter";
	const pumpDiameter = context.pumpDiameter;
	const pumpDiameterKey = "pumpDiameter";

	const pumpVelocityHeader = "Velocity";
	const pumpVelocity = context.pumpVelocity;
	const pumpVelocityKey = "pumpVelocity";

	return (
		<div className="item" style={{ margin }}>
			<div className="item--header">
				Dilution values
			</div>
			<div className="flex">
			<Value header={waterHeader} disabled
				update={updateValue} value={water} valueKey={waterKey}/>
			<Value header={pumpDiameterHeader}
				update={updateValue} value={pumpDiameter} valueKey={pumpDiameterKey}/>
			</div>
			<div className="flexthree">
				<Value header={pressureHeader} update={updateValue}
					value={pressure} valueKey={pressureKey}>
					<select onChange={e => updateUnit(e, pressureUnitKey)}>
						{
							Object.keys(PressureUnit).map((key: keyof typeof PressureUnit) => {
								const name = PressureUnit[key];
								return (
									<option key={key} value={key}>
										{name}
									</option>
								);
							})
						}
					</select>
				</Value>
				<Value header={waterCVHeader} disabled
					update={updateValue} value={waterCV} valueKey={waterCVKey}/>
				<Value header={pumpVelocityHeader} disabled
					update={updateValue} value={pumpVelocity} valueKey={pumpVelocityKey}/>
			</div>
		</div>
	);
};
