import * as React from "react";

type OptRadioStrProps = {
	callback: (event: React.ChangeEvent<HTMLInputElement>) => void;
	current: string;
	initialSelection: string;
	name: string;
};

/**
 * Subcomponent for form Options to create radio input for non-numeric values.
 *
 * @param props Contains callback function and needed values for input functionality.
 */
export const OptRadioStr: React.FC<OptRadioStrProps> = (props) => {

	return (
		<input type="radio" onChange={e => props.callback(e)}
			id={props.current}
			value={props.current}
			checked={props.current === props.initialSelection} />
	);
};
