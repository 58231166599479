import * as React from "react";
import { useDilutionState } from "./DilutionContext";
import { ItemProps } from "./ItemsContainer";
import { Value } from "./Value";

export const AfterDilution: React.FC<ItemProps> = ({updateValue, margin}) => {

	const context = useDilutionState();

	const consistency = context.afterConsistency;
	const consistencyKey = "afterConsistency";
	const consistencyHeader = "Consistency % Cs";

	const flowValue = context.afterFlow;
	const flowKey = "afterFlow";
	const flowHeader = "Flow rate";

	const velocityValue = context.afterVelocity;
	const velocityKey = "afterVelocity";
	const velocityHeader = "Velocity";

	return (
		<div className="item w50" style={{ margin }}>
			<div className="item--header">
				After dilution
			</div>
			<Value header={consistencyHeader}
				update={updateValue} value={consistency} valueKey={consistencyKey}/>
			<div className="flex">
				<Value header={flowHeader} disabled short
					update={updateValue} value={flowValue} valueKey={flowKey}>
				</Value>
				<Value header={velocityHeader} disabled short
					update={updateValue} value={velocityValue} valueKey={velocityKey}>
				</Value>
			</div>
		</div>
	);
};
