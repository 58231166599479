import * as React from "react";

type OptSelectProps = {
	callback: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	data: {[key: string]: string};
	initial: string;
};

/**
 * Subcomponent for form Options to generate a select list.
 *
 * @param props Contains callback function for onChange action, data object and
 * the initial value key.
 */
export const OptSelect: React.FC<OptSelectProps> = (props) => {

	return (
		<select onChange={e => props.callback(e)} value={props.initial}>
			{
				Object.keys(props.data).map(key => {
					return (
						<option value={key} key={key}>
							{props.data[key]}
						</option>
					);
				})
			}
		</select>
	);
};
