import * as React from "react";

type SummaryItemProps = {
	title: string;
	content: string | number;
};

/**
 * Component to streamline summary page data display.
 */
export const SummaryTextItem: React.FC<SummaryItemProps> = ({title, content}) => {

	return (
		<div className="item">
			<div className="item--header">
				{title}
			</div>
			<div className="item--text">
				{content}
			</div>
		</div>
	);
};
