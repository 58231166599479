export type MeasurementUnitValue = {
	name: string;
	unit: string;
	multiplier: number;
};

export type MeasurementUnit = {
	[key: string]: {
		values: MeasurementUnitValue[];
		step: number;
	};
};
export const measurementUnits: MeasurementUnit = {};

measurementUnits["length"] = {
	values: [
		{
			name: "inch",
			unit: " [in]",
			multiplier: 63360.23622,
		},
		{
			name: "foot",
			unit: " [ft]",
			multiplier: 5280.019685,
		},
		{
			name: "yard",
			unit: " [yd]",
			multiplier: 1760.0065617,
		},
		{
			name: "mile",
			unit: " [mi]",
			multiplier: 1.0,
		},
		{
			name: "millimeter",
			unit: " [mm]",
			multiplier: 1609350,
		},
		{
			name: "centimeter",
			unit: " [cm]",
			multiplier: 160935,
		},
		{
			name: "meter",
			unit: " [m]",
			multiplier: 1609.35,
		},
		{
			name: "kilometer",
			unit: " [km]",
			multiplier: 1.60935,
		},
	],
	step: 0.01,
};

measurementUnits["area"] = {
	values: [
		{
			name: "square millimeter",
			unit: " [mm\u00B2]",
			multiplier: 1000000,
		},
		{
			name: "square centimeter",
			unit: " [cm\u00B2]",
			multiplier: 10000,
		},
		{
			name: "square meter",
			unit: " [m\u00B2]",
			multiplier: 1,
		},
		{
			name: "square inch",
			unit: " [inch\u00B2]",
			multiplier: 1550.0031,
		},
		{
			name: "square foot",
			unit: " [ft\u00B2]",
			multiplier: 10.763910417,
		},
		{
			name: "square yard",
			unit: " [yd\u00B2]",
			multiplier: 1.1959900463,
		},
	],
	step: 0.01,
};
measurementUnits["Volume"] = {
	values: [
		{
			name: "cubic centimeter",
			unit: "",
			multiplier: 1000000,
		},
		{
			name: "cubic meter",
			unit: "",
			multiplier: 1,
		},
		{
			name: "liter",
			unit: "",
			multiplier: 1000,
		},
		{
			name: "cubic inch",
			unit: "",
			multiplier: 61023.744095,
		},
		{
			name: "cubic foot",
			unit: "",
			multiplier: 35.314666721,
		},
		{
			name: "US gallon",
			unit: "",
			multiplier: 264.17217686,
		},
		{
			name: "Imperial gallon",
			unit: "",
			multiplier: 219.9692483,
		},
		{
			name: "Us barrel for oil",
			unit: "",
			multiplier: 6.289811,
		},
	],
	step: 0.1,
};
measurementUnits["Mass"] = {
	values: [
		{
			name: "gram",
			unit: " [g]",
			multiplier: 1016046.08,
		},
		{
			name: "kilogram",
			unit: " [kg]",
			multiplier: 1016.04608,
		},
		{
			name: "metric ton",
			unit: " [tonne]",
			multiplier: 1.01604608,
		},
		{
			name: "short ton",
			unit: " [shton]",
			multiplier: 1.12,
		},
		{
			name: "long ton",
			unit: " [Lton]",
			multiplier: 1,
		},
		{
			name: "pound",
			unit: " [lb]",
			multiplier: 2240,
		},
		{
			name: "ounce",
			unit: " [oz]",
			multiplier: 35840,
		},
	],
	step: 0.1,
};
measurementUnits["Density"] = {
	values: [
		{
			name: "gram per milliliter",
			unit: " [g/ml]",
			multiplier: 27.679904593,
		},
		{
			name: "kilogram per cubic meter",
			unit: " [kg/m\u00B3]",
			multiplier: 27679.904593,
		},
		{
			name: "pound per cubic foot",
			unit: " [lb/ft\u00B3]",
			multiplier: 1728.0000000145,
		},
		{
			name: "pound per cubic inch",
			unit: " [lb/inch\u00B3]",
			multiplier: 1,
		},
	],
	step: 0.1,
};
measurementUnits["Speed"] = {
	values: [
		{
			name: "meter per second",
			unit: " [m/s]",
			multiplier: 0.2777777778,
		},
		{
			name: "meter per minute",
			unit: " [m/min]",
			multiplier: 16.666666667,
		},
		{
			name: "kilometer per hour",
			unit: " [km/h]",
			multiplier: 1,
		},
		{
			name: "feet per second",
			unit: " [ft/s]",
			multiplier: 0.9113444153,
		},
		{
			name: "feet per minute",
			unit: " [ft/min]",
			multiplier: 54.680664917,
		},
	],
	step: 0.1,
};
measurementUnits["Pressure"] = {
	values: [
		{
			name: "bar",
			unit: "",
			multiplier: 10,
		},
		{
			name: "pound per square inch",
			unit: " [psi]",
			multiplier: 145.03773773,
		},
		{
			name: "kilo Pascal",
			unit: " [kPa]",
			multiplier: 1000,
		},
		{
			name: "Mega Pascal",
			unit: " [MPa]",
			multiplier: 1,
		},
		{
			name: "kilogram force per centimeter",
			unit: " [kgf/cm\u00B2]",
			multiplier: 10.19716213,
		},
		{
			name: "millimeter of mercury",
			unit: " [mmHg,torr]",
			multiplier: 7500.6375542,
		},
		{
			name: "Int. St. Atm. ",
			unit: " [atm]",
			multiplier: 9.8692326672,
		},
		{
			name: "meter of water",
			unit: " [mH\u2082O]",
			multiplier: 101.9744,
		},
		{
			name: "foot of water",
			unit: " [ftH\u2082O]",
			multiplier: 334.56229215,
		},
		{
			name: "inch of water",
			unit: " [inH\u2082O]",
			multiplier: 4014.7421331,
		},
		{
			name: "centimeter of mercury",
			unit: " [cmHg]",
			multiplier: 750.061682704,
		},
		{
			name: "inch of mercury",
			unit: " [inHg]",
			multiplier: 295.2998016471232,
		},
		{
			name: "Pascal = N/m²",
			unit: " [Pa]",
			multiplier: 1000000,
		},
		{
			name: "mmH\u00B2O, kp/m\u00B2",
			unit: " [kp/m\u00B2]",
			multiplier: 101971.62129779,
		},
	],
	step: 0.1,
};
measurementUnits["Temperature"] = {
	values: [
		{
			name: "Celsius",
			unit: "",
			multiplier: 1,
		},
		{
			name: "Fahrenheit",
			unit: "",
			multiplier: 0,
		},
		{
			name: "Kelvin",
			unit: "",
			multiplier: 0,
		},
		{
			name: "Rankine",
			unit: "",
			multiplier: 0,
		},
	],
	step: 0.1,
};
export const CSF_INTERPOLATION_VALS = [
	50,
	75,
	100,
	125,
	150,
	175,
	200,
	225,
	250,
	275,
	300,
	325,
	350,
	375,
	400,
	425,
	450,
	475,
	500,
	525,
	550,
	575,
	600,
	625,
	650,
	675,
	700,
	725,
	750,
	775,
	800,
	825,
	850,
	875,
	900,
];
export const SR_INTERPOLATION_VALS = [
	80,
	73.2,
	68,
	63.2,
	59,
	54.8,
	51.5,
	48.3,
	45.4,
	43,
	40.3,
	38,
	36,
	34,
	32,
	30,
	28.5,
	26.7,
	25.3,
	23.7,
	22.5,
	21,
	20,
	18.6,
	17.5,
	16.5,
	15.5,
	14.4,
	13.3,
	12.2,
	11,
	9.7,
	8.5,
	7.1,
	5.5,
];

measurementUnits["Freeness"] = {
	values: [
		{
			name: "CSF",
			unit: "",
			multiplier: 0,
		},
		{
			name: "SR",
			unit: "",
			multiplier: 0,
		},
	],
	step: 0.1,
};
measurementUnits["Conductivity"] = {
	values: [
		{
			name: "millisiemens/cm",
			unit: "",
			multiplier: 0.01,
		},
		{
			name: "millisiemens/m",
			unit: "",
			multiplier: 1,
		},
		{
			name: "millisiemens/inch",
			unit: "",
			multiplier: 0.025399999999972566,
		},
		{
			name: "umhos/inch",
			unit: "",
			multiplier: 25.4,
		},
	],
	step: 0.1,
};
measurementUnits["Liquidflow"] = {
	values: [
		{
			name: "liter per second",
			unit: " [l/sec]",
			multiplier: 1,
		},
		{
			name: "liter per minute",
			unit: " [l/min]",
			multiplier: 60,
		},
		{
			name: "cubic meters per hour",
			unit: " [m\u00B3/h]",
			multiplier: 3.6,
		},
		{
			name: "cubic feet per minute",
			unit: " [ft\u00B3/min]",
			multiplier: 2.1188800032,
		},
		{
			name: "cubic feet per hour",
			unit: " [ft\u00B3/h]",
			multiplier: 127.1328002,
		},
		{
			name: "US gallon per minute",
			unit: " [gal/min]",
			multiplier: 15.850323141,
		},
		{
			name: "US barrel per day",
			unit: " [brl/d]",
			multiplier: 543.43965057,
		},
	],
	step: 0.1,
};
measurementUnits["Gasflow"] = {
	values: [
		{
			name: "normal cubic meter per hour",
			unit: " [Nm\u00B3/hr]",
			multiplier: 1179.8497301,
		},
		{
			name: "st. cubic ft per hour",
			unit: " [scfh]",
			multiplier: 41666.6666667,
		},
		{
			name: "st. cubic ft per min",
			unit: " [scfm]",
			multiplier: 694.444444445,
		},
		{
			name: "10\u00B3 st. cubic ft per day",
			unit: " [mscfd]",
			multiplier: 1000,
		},
		{
			name: "10\u2076 st. cubic ft per day",
			unit: " [mmscfd]",
			multiplier: 1,
		},
		{
			name: "normal liter per second",
			unit: " [Nl/sec]",
			multiplier: 327.73603614,
		},
	],
	step: 0.1,
};
measurementUnits["Massflow"] = {
	values: [
		{
			name: "kilogram per hour",
			unit: " [kg/h]",
			multiplier: 3600,
		},
		{
			name: "pound per hour",
			unit: " [lb/h]",
			multiplier: 7936.6414387,
		},
		{
			name: "kilogram per second",
			unit: " [kg/s]",
			multiplier: 1,
		},
		{
			name: "ton per hour",
			unit: " [t/h]",
			multiplier: 3.6,
		},
	],
	step: 0.1,
};
measurementUnits["Torque"] = {
	values: [
		{
			name: "Newton meter",
			unit: " [Nm]",
			multiplier: 9.80665,
		},
		{
			name: "kilogram force meter",
			unit: " [kgfm]",
			multiplier: 1,
		},
		{
			name: "foot pound",
			unit: " [ftlb]",
			multiplier: 7.2330135756,
		},
		{
			name: "inch pound",
			unit: " [inlb]",
			multiplier: 86.796162907,
		},
	],
	step: 0.1,
};
measurementUnits["Dynamic viscosity"] = {
	values: [
		{
			name: "centipoise, mPa*s",
			unit: " [cp]",
			multiplier: 47880.25898,
		},
		{
			name: "g/(cm/*s) = dyne*s/cm\u00B2",
			unit: " [poise]",
			multiplier: 478.8025898,
		},
		{
			name: "pound per foot second",
			unit: " [lb/(ft*s)]",
			multiplier: 32.174048556,
		},
		{
			name: "pound force sec. per ft\u00B2",
			unit: " [lbf*s/ft\u00B2]",
			multiplier: 1,
		},
		{
			name: "Ns/m\u00B2 = kg/(m*s) = Pl",
			unit: " [Ns/m\u00B2]",
			multiplier: 47.88025898,
		},
	],
	step: 0.1,
};
measurementUnits["Kinematic viscosity"] = {
	values: [
		{
			name: "centistoke",
			unit: " [cs, cSt]",
			multiplier: 1000000,
		},
		{
			name: "stoke = cm\u00B2/s",
			unit: " [ST,stoke]",
			multiplier: 10000,
		},
		{
			name: "square feet per second",
			unit: " [ft\u00B2/s]",
			multiplier: 10.763910417,
		},
		{
			name: "square meter per second",
			unit: " [m\u00B2/s]",
			multiplier: 1,
		},
	],
	step: 0.1,
};
