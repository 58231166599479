import * as React from "react";
import { Mounting } from "../Constants";
import { useConsistencyState, useUpdateConsistency } from "./ConsistencyContext";
import { PipeDataRow } from "./PipeDataRow";

type MountingOptionProps = {
	callback?: (key: string, checked: boolean) => void;
	checked?: boolean;
	value: string;
	L1title: string;
	L1: string;
	L2title: string;
	L2: string;
	unit: string;
	notes?: boolean;
};

/**
 * Component for layouting the mounting options given.
 * Optional callback is for user selection on the requirements page.
 * Optional notes field is displayed by default only on the summary page.
 */
export const MountingOption: React.FC<MountingOptionProps> = (props) => {

	const ccontext = useConsistencyState();
	const updateConsistency = useUpdateConsistency();

	const mKey = props.value as keyof typeof Mounting;

	const updateNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		let text = event.target.value;
		let selections = ccontext.mountingSelection;
		selections[mKey].notes = text;
		updateConsistency({...ccontext, mountingSelection: selections });
	};

	const clickHandler = (value: string, checked: boolean) => {
		if (props.callback) {
			props.callback(value, checked);
		}
	};

	return (
		<div className="item">
			<div className="item--image" style={{ backgroundImage: "url('./assets/pipe_" + mKey + ".jpg')"}}
				onClick={e => { clickHandler(props.value, !props.checked); }}>
				{
					props.callback &&
					<div className="item--image--radio">
						<input type="checkbox" value={props.value} checked={props.checked}
							onChange={e => {
								// We use the parent div handler for handling checkbox value.
								// This onChange suppresses errors since "defaultChecked" does not
								// work correctly for this purpose.
							}}
							name="mountingopt"/>
					</div>
				}
			</div>
			<div className="item--values">
				<PipeDataRow title={props.L1title}
					value={props.L1} unit={props.unit}/>
				<PipeDataRow title={props.L2title}
					value={props.L2} unit={props.unit}/>
				{
					props.notes &&
					<div className="item--values--notes">
						<div className="item--values--notes--title">
							Notes:
						</div>
						<div className="item--values--notes--area">
							<textarea rows={2} spellCheck={false} placeholder={"Add your notes here"}
								value={ccontext.mountingSelection[mKey].notes} onChange={updateNotes}/>
						</div>
					</div>
				}
			</div>
		</div>
	);
};
