import * as React from "react";
import { Page } from "./Constants";

/**
 * Functional component to handle rendering the landing page.
 * Clicking on an app icon fires the callback to switch to that app.
 */
export const LandingPage: React.FC<{}> = () => {
	return (
		<div className="landingpage">
			<div className="landingpage-wrap">
				<div
					className="applink big"
					onClick={() => {
						window.location.pathname = Page.GeneralDataPage;
					}}
				>
					<div className="imagewrap"></div>
					<div className="header">Valmet SP installation tool</div>
					<div className="description">
						Calculation of the installation position in the process
					</div>
				</div>

				<div className="secondary-links">
					<div
						className="applink"
						onClick={() => {
							window.location.pathname = Page.TutorialsPage;
						}}
					>
						<div
							className="bgimg"
							style={{
								backgroundImage:
									"url(./assets/consistency_tutorial.jpg)",
							}}
						/>
						<div className="header">Consistency tutorial</div>
						<div className="description">
							Tips for a successful consistency installation
						</div>
					</div>
					<div
						className="applink"
						onClick={() => {
							window.location.pathname = Page.DilutionPage;
						}}
					>
						<div
							className="bgimg"
							style={{
								backgroundImage:
									"url(./assets/dilution_calculator.jpg)",
							}}
						/>
						<div className="header">Dilution calculator</div>
						<div className="description">
							Calculation of the pulp consistency dilution
						</div>
					</div>
					<div
						className="doclink"
						onClick={() => {
							window.location.pathname = Page.MaterialsPage;
						}}
					>
						<div
							className="bgimg"
							style={{
								backgroundImage:
									"url(./assets/materials.jpg)",
							}}
						/>
						<div className="header">Download materials</div>
						<div className="description">
							Read more about Valmet Consistency solutions
						</div>
					</div>
					<div
						className="doclink"
						onClick={() => {
							window.location.pathname = Page.UnitConverterPage;
						}}
					>
						<div
							className="bgimg"
							style={{
								backgroundImage:
									"url(./assets/unit_converter.jpg)",
							}}
						/>
						<div className="header">Unit converter</div>
						<div className="description">
							Conversion of standard units
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
