import * as React from "react";
import { dilutionDefaults, DilutionData } from "../Constants";

type Dispatch = React.Dispatch<React.SetStateAction<DilutionData>>;

const DilutionState
	= React.createContext<DilutionData | undefined>(undefined);
const UpdateDilution
	= React.createContext<Dispatch | undefined>(undefined);

/**
 * Context provider as a component. Renders it's children
 * inside it's context.Providers.
 */
export const DilutionProvider: React.FC<{}> = ({children}) => {
	const dataKey = "dilutionData";
	let initialValues = dilutionDefaults;
	// Load initial values from localStorage if available.
	if ('localStorage' in window && window['localStorage'] !== null) {
		const localStr = window.localStorage;
		try {
			const storedState = localStr.getItem(dataKey);
			if (storedState) {
				initialValues = JSON.parse(storedState);
			}
		} catch (e) {
			console.warn(e);
		}
	}
	const [dilutionState, updateDilutionState] = React.useState(initialValues);

	const dataUpdate = (state: DilutionData) => {
		// save localStorage
		if ('localStorage' in window && window['localStorage'] !== null) {
			const localStr = window.localStorage;
			try {
				localStr.setItem(dataKey, JSON.stringify(state));
			} catch (e) {
				console.warn(e);
			}
		}
		updateDilutionState(state);
	};

	return (
		<DilutionState.Provider value={dilutionState}>
			<UpdateDilution.Provider value={dataUpdate}>
				{children}
			</UpdateDilution.Provider>
		</DilutionState.Provider>
	);
};

/**
 * Function for loading the context state from a child component.
 * Outside the provider leads to an error.
 */
export const useDilutionState: () => DilutionData = () => {
	const context = React.useContext(DilutionState);
	if (context === undefined) {
		throw new Error('useDilutionState must be used within provider');
	}
	return context;
};

/**
 * Function for loading the context state update function from a child component.
 * Outside the provider leads to an error.
 */
export const useUpdateDilution: () => Dispatch = () => {
	const context = React.useContext(UpdateDilution);
	if (context === undefined) {
		throw new Error('useUpdateDilution must be used within provider');
	}
	return context;
};
