import * as React from "react";
import { DilutionData } from "../Constants";

type ValueProps = {
	value: number | "";
	header: string;
	valueKey: keyof DilutionData;
	update: (value: string, key: keyof DilutionData) => void;
	short?: boolean;
	disabled?: boolean;
	secondHeader?: string;
};

/**
 * Wrapper component to handle a value display.
 */
export const Value: React.FC<ValueProps> = (props) => {

	// Apparently checks when calculating just wouldn't work
	// so check for empty string or NaN here.
	let value = props.value as number;
	if (value === null) {
		value = 0;
	}
	if (isNaN(value)) {
		value = 0;
	}

	return (
		<div className={"row" + (props.short ? " w50" : "")}>
			<div className="row--headers">
				<div className="row--headers--main">
					{props.header}
				</div>
				{
					props.secondHeader &&
					<div className="row--headers--secondary">
						<div className="row--headers--secondary--filler"></div>
						<div className="row--headers--secondary--header">
							{props.secondHeader}
						</div>
					</div>
				}
			</div>
			<div className="row--wrap">
				<div className="row--wrap--value">
					{
						<input value={value} onBlur={e => props.update(e.target.value, props.valueKey)}
							onChange={e => props.update(e.target.value, props.valueKey)}
							disabled={props.disabled}/>
					}
				</div>
				{
					props.children &&
					<div className="row--wrap--unit">
						{props.children}
					</div>
				}
			</div>
		</div>
	);
};
