import * as React from "react";
import { measurementUnits } from "./MeasurementUnits";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faEquals } from "@fortawesome/free-solid-svg-icons";
import { CSF_INTERPOLATION_VALS } from "./MeasurementUnits";
import { SR_INTERPOLATION_VALS } from "./MeasurementUnits";

/**
 * Unit converter block on dilution calculator page.
 */

export const UnitConverter: React.FC<{
	margin?: string | undefined;
	hideTitle?: boolean;
}> = ({ margin, hideTitle }) => {
	const [selectedCategory, updateselectedCategory] = React.useState("length");
	const [selectedInputUnit, updateselectedInputUnit] = React.useState("inch");
	const [selectedOutputUnit, updateselectedOutputUnit] = React.useState("inch");
	const [inputFieldValue, updateinputFieldValue] = React.useState("");
	const [outputFieldValue, updateoutputFieldValue] = React.useState("");
	const [inputMultiplier, updateinputMultiplier] = React.useState(1.0);
	const [outputMultiplier, updateoutputMultiplier] = React.useState(1.0);

	const reset = (event: React.ChangeEvent<HTMLSelectElement>) => {
		updateselectedCategory(event.target.value);
		updateselectedInputUnit(measurementUnits[event.target.value].values[0].name);
		updateselectedOutputUnit(measurementUnits[event.target.value].values[0].name);
		updateinputFieldValue("");
		updateoutputFieldValue("");
		updateinputMultiplier(1.0);
		updateoutputMultiplier(1.0);
	};

	const swapInOutPut = () => {
		const tempOMultiplier = outputMultiplier;
		const tempIMultiplier = inputMultiplier;
		const tempInput = selectedInputUnit;
		const tempOutput = selectedOutputUnit;
		const tempIValue = inputFieldValue;
		const tempOValue = outputFieldValue;
		updateselectedOutputUnit(tempInput);
		updateinputFieldValue(tempOValue);
		updateselectedInputUnit(tempOutput);
		updateoutputFieldValue(tempIValue);
		updateinputMultiplier(tempOMultiplier);
		updateoutputMultiplier(tempIMultiplier);
	};

	const updateInputUnit = (value: string) => {
		updateselectedInputUnit(value);
		convertUnit(value, selectedOutputUnit);
	};

	const updateOutputUnit = (value: string) => {
		updateselectedOutputUnit(value);
		convertUnit(selectedInputUnit, value);
	};
	const updateOutputUnitFreeness = (value: string) => {
		updateselectedOutputUnit(value);
		if (selectedInputUnit === "CSF") {
			csfConversion(inputFieldValue, value);
		}
		if (selectedInputUnit === "SR") {
			srConversion(inputFieldValue, value);
		}
	};
	const updateOutputUnitTemperature = (value: string) => {
		updateselectedOutputUnit(value);

		if (selectedInputUnit === "Celsius") {
			celsiusConversion(inputFieldValue, value);
		}
		if (selectedInputUnit === "Rankine") {
			rankineConversion(inputFieldValue, value);
		}
		if (selectedInputUnit === "Fahrenheit") {
			fahrenheitConversion(inputFieldValue, value);
		}
		if (selectedInputUnit === "Kelvin") {
			kelvinConversion(inputFieldValue, value);
		}
	};

	const convertUnit = (inputVal: string, outputVal: string) => {
		let inputCalc = measurementUnits[selectedCategory].values.filter(
			(v) => {
				return v.name === inputVal;
			},
		);
		let outputCalc = measurementUnits[selectedCategory].values.filter(
			(v) => {
				return v.name === outputVal;
			},
		);
		const iMultiplier = inputCalc[0].multiplier;
		const oMultiplier = outputCalc[0].multiplier;
		updateinputMultiplier(iMultiplier);
		updateoutputMultiplier(oMultiplier);
		const result =
			(parseFloat(inputFieldValue) / iMultiplier) * oMultiplier;
		updateoutputFieldValue(result.toString());
	};

	const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		updateinputFieldValue(event.target.value);
		const result =
			(parseFloat(event.target.value) / inputMultiplier) *
			outputMultiplier;
		updateoutputFieldValue(result.toString());
	};

	const celsiusConversion = (
		value: string,
		outputUnit = selectedOutputUnit,
	) => {
		let result = 0;
		if (outputUnit === "Fahrenheit") {
			result = (parseFloat(value) * 9) / 5 + 32;
			updateoutputFieldValue(result.toString());
		} else if (outputUnit === "Kelvin") {
			result = parseFloat(value) + 273.15;
			updateoutputFieldValue(result.toString());
		} else if (outputUnit === "Rankine") {
			result = (parseFloat(value) * 9) / 5 + 491.67;
			updateoutputFieldValue(result.toString());
		} else {
			updateoutputFieldValue(value);
		}
	};
	const fahrenheitConversion = (
		value: string,
		outputUnit = selectedOutputUnit,
	) => {
		let result = 0;
		if (outputUnit === "Celsius") {
			result = parseFloat(value) - (32 * 5) / 9;
			updateoutputFieldValue(result.toString());
		} else if (outputUnit === "Kelvin") {
			result = parseFloat(value) - (32 * 5) / 9 + 273.15;
			updateoutputFieldValue(result.toString());
		} else if (outputUnit === "Rankine") {
			result = parseFloat(value) + 459.67;
			updateoutputFieldValue(result.toString());
		} else {
			updateoutputFieldValue(value);
		}
	};
	const kelvinConversion = (
		value: string,
		outputUnit = selectedOutputUnit,
	) => {
		let result = 0;
		if (outputUnit === "Celsius") {
			result = parseFloat(value) - 273.15;
			updateoutputFieldValue(result.toString());
		} else if (outputUnit === "Fahrenheit") {
			result = ((parseFloat(value) - 273.15) * 9) / 5 + 32;
			updateoutputFieldValue(result.toString());
		} else if (outputUnit === "Rankine") {
			result = parseFloat(value) * 1.8;
			updateoutputFieldValue(result.toString());
		} else {
			updateoutputFieldValue(value);
		}
	};
	const rankineConversion = (
		value: string,
		outputUnit = selectedOutputUnit,
	) => {
		let result = 0;
		if (outputUnit === "Celsius") {
			result = ((parseFloat(value) - 491.67) * 5) / 9;
			updateoutputFieldValue(result.toString());
		} else if (outputUnit === "Kelvin") {
			result = (parseFloat(value) * 5) / 9;
			updateoutputFieldValue(result.toString());
		} else if (outputUnit === "Fahrenheit") {
			result = parseFloat(value) - 459.67;
			updateoutputFieldValue(result.toString());
		} else {
			updateoutputFieldValue(value);
		}
	};
	const srConversion = (value: string, outputUnit = selectedOutputUnit) => {
		let result = 0;
		let p = 0;
		let n = 0;
		let input = parseFloat(value);
		if (outputUnit !== "CSF") {
			updateoutputFieldValue(input.toString());
			return;
		}

		if (input < 5.5 || input > 80) {
			updateoutputFieldValue("out of range");
			return;
		}
		let index = SR_INTERPOLATION_VALS.indexOf(input);
		if (index !== -1) {
			result = CSF_INTERPOLATION_VALS[index];
			updateoutputFieldValue(result.toString());
			return;
		}
		for (let i = 0; i < SR_INTERPOLATION_VALS.length; i++) {
			if (input > SR_INTERPOLATION_VALS[i]) {
				p = i - 1;
				n = i;
				result =
					(CSF_INTERPOLATION_VALS[n] - CSF_INTERPOLATION_VALS[p]) /
						((SR_INTERPOLATION_VALS[n] - SR_INTERPOLATION_VALS[p]) /
							(input - SR_INTERPOLATION_VALS[p])) +
					CSF_INTERPOLATION_VALS[p];
				updateoutputFieldValue(result.toString());
				return;
			}
		}
	};
	const csfConversion = (value: string, outputUnit = selectedOutputUnit) => {
		let result = 0;
		let p = 0;
		let n = 0;
		let input = parseFloat(value);
		if (outputUnit !== "SR") {
			updateoutputFieldValue(input.toString());
			return;
		}

		if (input < 50 || input > 900) {
			updateoutputFieldValue("out of range");
			return;
		}
		let index = CSF_INTERPOLATION_VALS.indexOf(input);
		if (index !== -1) {
			result = SR_INTERPOLATION_VALS[index];
			updateoutputFieldValue(result.toString());
			return;
		}
		for (let i = 0; i < CSF_INTERPOLATION_VALS.length; i++) {
			if (input < CSF_INTERPOLATION_VALS[i]) {
				p = i - 1;
				n = i;
				result =
					(SR_INTERPOLATION_VALS[n] - SR_INTERPOLATION_VALS[p]) /
						((CSF_INTERPOLATION_VALS[n] -
							CSF_INTERPOLATION_VALS[p]) /
							(input - CSF_INTERPOLATION_VALS[p])) +
					SR_INTERPOLATION_VALS[p];
				updateoutputFieldValue(result.toString());
				return;
			}
		}
	};
	const selectedValueFormElement = (): JSX.Element | null => {
		if (
			measurementUnits[selectedCategory] === measurementUnits["Freeness"]
		) {
			return (
				<form>
					<div className="FreenessRange">
						CSF range 50 - 900 | SR range 5.5 - 80
					</div>
					<div className="unitconverterPage">
						<div className="convertInput">
							<span>From</span>
							<select
								className="inputfield"
								value={selectedInputUnit}
								onChange={(e) =>
									updateInputUnit(e.target.value)
								}
							>
								{measurementUnits[selectedCategory].values.map(
									(v) => {
										return (
											<option value={v.name} key={v.name}>
												{v.name}
												{v.unit}
											</option>
										);
									},
								)}
							</select>

							<input
								className="inputfieldvalue"
								type="number"
								step="0.001"
								value={inputFieldValue}
								onChange={(e) => {
									updateinputFieldValue(e.target.value);
									if (selectedInputUnit === "CSF") {
										csfConversion(e.target.value);
									}
									if (selectedInputUnit === "SR") {
										srConversion(e.target.value);
									}
								}}
							/>
						</div>
						<div className="swapbuttoncontainer">
							<button
								type="button"
								onClick={swapInOutPut}
								className="swapButton"
							>
								<FontAwesomeIcon
									icon={faExchangeAlt}
								></FontAwesomeIcon>
							</button>
							<div className="equalicon centeredText">
								<FontAwesomeIcon icon={faEquals} />
							</div>
						</div>
						<div className="convertOutput">
							<span>To</span>
							<select
								className="outputfield"
								value={selectedOutputUnit}
								onChange={(e) =>
									updateOutputUnitFreeness(e.target.value)
								}
							>
								{measurementUnits[selectedCategory].values.map(
									(v) => {
										return (
											<option value={v.name} key={v.name}>
												{v.name}
												{v.unit}
											</option>
										);
									},
								)}
							</select>
							<input
								className="outputfield"
								type="text"
								disabled
								value={outputFieldValue}
							/>
						</div>
					</div>
				</form>
			);
		}
		if (
			measurementUnits[selectedCategory] ===
			measurementUnits["Temperature"]
		) {
			return (
				<form>
					<div className="unitconverterPage">
						<div className="convertInput">
							<span>From</span>
							<select
								className="inputfield"
								value={selectedInputUnit}
								onChange={(e) =>
									updateInputUnit(e.target.value)
								}
							>
								{measurementUnits[selectedCategory].values.map(
									(v) => {
										return (
											<option value={v.name} key={v.name}>
												{v.name}
												{v.unit}
											</option>
										);
									},
								)}
							</select>
							<input
								className="inputfieldvalue"
								type="number"
								step="0.001"
								value={inputFieldValue}
								onChange={(e) => {
									updateinputFieldValue(e.target.value);
									if (selectedInputUnit === "Celsius") {
										celsiusConversion(e.target.value);
									}
									if (selectedInputUnit === "Kelvin") {
										kelvinConversion(e.target.value);
									}
									if (selectedInputUnit === "Fahrenheit") {
										fahrenheitConversion(e.target.value);
									}
									if (selectedInputUnit === "Rankine") {
										rankineConversion(e.target.value);
									}
								}}
							/>
						</div>
						<div className="swapbuttoncontainer">
							<button
								type="button"
								onClick={swapInOutPut}
								className="swapButton"
							>
								<FontAwesomeIcon
									icon={faExchangeAlt}
								></FontAwesomeIcon>
							</button>
							<div className="equalicon">
								<FontAwesomeIcon icon={faEquals} />
							</div>
						</div>
						<div className="convertOutput">
							<span>To</span>
							<select
								className="outputfield"
								value={selectedOutputUnit}
								onChange={(e) =>
									updateOutputUnitTemperature(e.target.value)
								}
							>
								{measurementUnits[selectedCategory].values.map(
									(v) => {
										return (
											<option value={v.name} key={v.name}>
												{v.name}
												{v.unit}
											</option>
										);
									},
								)}
							</select>
							<input
								className="outputfield"
								type="number"
								disabled
								value={outputFieldValue}
							/>
						</div>
					</div>
				</form>
			);
		} else {
			return (
				<form>
					<div className="unitconverterPage">
						<div className="convertInput">
							<span>From</span>
							<select
								className="inputfield"
								value={selectedInputUnit}
								onChange={(e) =>
									updateInputUnit(e.target.value)
								}
							>
								{measurementUnits[selectedCategory].values.map(
									(v) => {
										return (
											<option value={v.name} key={v.name}>
												{v.name}
												{v.unit}
											</option>
										);
									},
								)}
							</select>
							<input
								className="inputfieldvalue"
								type="number"
								step="0.001"
								value={inputFieldValue}
								onChange={inputChangeHandler}
							/>
						</div>
						<div className="swapbuttoncontainer">
							<button
								type="button"
								onClick={swapInOutPut}
								className="swapButton"
							>
								<FontAwesomeIcon
									icon={faExchangeAlt}
								></FontAwesomeIcon>
							</button>
							<div className="equalicon">
								<FontAwesomeIcon icon={faEquals} />
							</div>
						</div>
						<div className="convertOutput">
							<span>To</span>
							<select
								className="outputfield"
								value={selectedOutputUnit}
								onChange={(e) =>
									updateOutputUnit(e.target.value)
								}
							>
								{measurementUnits[selectedCategory].values.map(
									(v) => {
										return (
											<option value={v.name} key={v.name}>
												{v.name}
												{v.unit}
											</option>
										);
									},
								)}
							</select>
							<input
								className="outputfield"
								type="number"
								disabled
								value={outputFieldValue}
							/>
						</div>
					</div>
				</form>
			);
		}
	};

	return (
		<div className="converterpage">
			<div className="item" style={{ margin }}>
				{!hideTitle && (
					<div className="item--header">Unit converter</div>
				)}
				<div className="wrap">
					<select
						className="unitconverterfield"
						value={selectedCategory}
						onChange={(e) => reset(e)}
					>
						<option value="length">length</option>
						<option value="area">Area</option>
						<option value="Volume">Volume</option>
						<option value="Mass">Mass</option>
						<option value="Density">Density</option>
						<option value="Speed">Speed</option>
						<option value="Pressure">Pressure</option>
						<option value="Temperature">Temperature</option>
						<option value="Freeness">Freeness</option>
						<option value="Conductivity">Conductivity</option>
						<option value="Liquidflow">Liquid flow</option>
						<option value="Gasflow">Gas flow</option>
						<option value="Massflow">Mass flow</option>
						<option value="Torque">Torque</option>
						<option value="Dynamic viscosity">
							Dynamic viscosity
						</option>
						<option value="Kinematic viscosity">
							Kinematic viscosity
						</option>
					</select>
				</div>
				{selectedValueFormElement()}
			</div>
		</div>
	);
};
