import * as React from "react";
import { FlowUnit, Mounting, PipeUnit, PulpNames, TRANSMITTERS, VelocityUnit, MATERIALS} from "../Constants";
import { useGeneralState } from "../GeneralContext";
import { useConsistencyState } from "./ConsistencyContext";
import { MountingOption } from "./MountingOption";
import { PulpData } from "./PulpData";
import { ResponsiveCanvas } from "./ResponsiveCanvas";
import { SummaryTextItem } from "./SummaryTextItem";

/**
 * Summary page for the consistency calculations.
 * Handles layouting for the results and relevant key data.
 */
export const Summary: React.FC<{}> = () => {

	const generalContext = useGeneralState();
	const ccontext = useConsistencyState();
	const pipeCalculations = PulpData.calculatePipeValues(ccontext);

	const today = new Date();
	const day = ("0" + today.getDate()).slice(-2);
	const month = ("0" + (today.getMonth() + 1)).slice(-2);
	const year = today.getFullYear();

	const printHandler = () => {
		window.print();
	};

	return (
		<div id="summarypage">
			<div className="watermark">
				<div className="watermark--image">

				</div>
				<div className="watermark--image offset">

				</div>
				<div className="watermark--image">

				</div>
			</div>
			<div className="logo">
				<img className="logo--image" src="./assets/logo.png"></img>
			</div>
			<div className="header">
				<div className="header--title">
					Summary
				</div>
				<div className="header--date">
					{
						day + "." +
						month + "." +
						year
					}
				</div>
			</div>
			<div className="canvaswrap">
				<div className="flexwrap">
					<div className="general">
						<SummaryTextItem title={"Mill:"} content={generalContext.mill}/>
						<SummaryTextItem title={"Application:"} content={generalContext.application}/>
						<SummaryTextItem title={"Tag number:"} content={generalContext.tag}/>
						<SummaryTextItem title={"Transmitter:"} content={TRANSMITTERS[ccontext.transmitterType]}/>
						<SummaryTextItem title={"Sensor type:"} content={ccontext.sensorType}/>
						<SummaryTextItem title={"Sensor material:"} content={MATERIALS[ccontext.sensorMaterial]}/>
						<div className="general--notes">
							<div className="general--notes--header">
								User notes:
							</div>
							<div className="general--notes--text">
								{generalContext.notes}
							</div>
						</div>
					</div>
					<div className="header">
						<div className="header--title">
							Application
						</div>
					</div>
					<div className="calculation">
						<SummaryTextItem title={"Pulp grade:"} content={PulpNames[ccontext.pulpType]}/>
						<SummaryTextItem title={"Consistency operating setpoint:"}
							content={ccontext.consistencyMin + " - " + ccontext.consistencyMax + " %"}/>
						<SummaryTextItem title={"Flow range:"}
							content={ccontext.flowMin + " - " + ccontext.flowMax + " " + FlowUnit[ccontext.flowUnit]}/>
						<SummaryTextItem title={"Velocity range:"}
							content={ccontext.velocityMin + " - " + ccontext.velocityMax
							+ " " + VelocityUnit[ccontext.velocityUnit]}/>
						<SummaryTextItem title={"Pipe diam. at measurement point:"}
							content={ccontext.pipeDiameter + " " + PipeUnit[ccontext.pipeUnit]}/>
						<SummaryTextItem title={"Max. velocity:"}
							content={ccontext.velocityMax + " " + VelocityUnit[ccontext.velocityUnit]}/>
						<SummaryTextItem title={"Min. consistency:"} content={ccontext.consistencyMin + " % Cs"}/>
					</div>
				</div>
				<div className="canvas">
					<ResponsiveCanvas />
				</div>
			</div>
			<div className="mounting">
				<div className="flex">
					{
						Object.keys(pipeCalculations).map((key: keyof typeof Mounting) => {
							if (ccontext.mountingSelection[key].selected) {
								const opt = pipeCalculations[key];
								return (
									<MountingOption value={key} L1title={"Upstream (L1)"} L1={opt!.L1.toFixed(0)}
										L2title={"Downstream (L2)"} L2={opt!.L2.toFixed(0)}
										unit={ccontext.pipeUnit} notes={true} key={key}/>
								);
							}
						})
					}
				</div>
			</div>
			<div className="printfooter">
				<div className="printfooter--text">
					Measurement Advisor
				</div>
			</div>
			<div className="download">
					<button onClick={printHandler}>Download as PDF</button>
			</div>
			{/*
			Printing images that are not cached as their setup on MountingOption component
			is rather dynamic, is apparently a problem so we cache the images here.
			*/}
			<div><img src="./assets/pipe_A.jpg" style={{ display: "none" }}/></div>
			<div><img src="./assets/pipe_B1.jpg" style={{ display: "none" }}/></div>
			<div><img src="./assets/pipe_B2.jpg" style={{ display: "none" }}/></div>
			<div><img src="./assets/pipe_C.jpg" style={{ display: "none" }}/></div>
			<div><img src="./assets/pipe_D.jpg" style={{ display: "none" }}/></div>
		</div>
	);
};
