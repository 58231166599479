import * as React from "react";
import { useDilutionState } from "./DilutionContext";
import { ItemProps } from "./ItemsContainer";
import { Value } from "./Value";

export const BeforeDilution: React.FC<ItemProps> = ({updateValue, margin}) => {

	const context = useDilutionState();

	const consistency = context.beforeConsistency;
	const consistencyKey = "beforeConsistency";
	const consistencyHeader = "Consistency % Cs";

	const flowValue = context.beforeFlow;
	const flowKey = "beforeFlow";
	const flowHeader = "Flow rate";

	const velocityValue = context.beforeVelocity;
	const velocityKey = "beforeVelocity";
	const velocityHeader = "Velocity";

	return (
		<div className="item w50" style={{ margin }}>
			<div className="item--header">
				Before dilution
			</div>
			<Value header={consistencyHeader}
				update={updateValue} value={consistency} valueKey={consistencyKey}/>
			<div className="flex">
				<Value header={flowHeader} disabled short
					update={updateValue} value={flowValue} valueKey={flowKey}>
				</Value>
				<Value header={velocityHeader} disabled short
					update={updateValue} value={velocityValue} valueKey={velocityKey}>
				</Value>
			</div>
		</div>
	);
};
