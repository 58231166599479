import * as React from "react";
import { Link } from "react-router-dom";
import { Page } from "../Constants";

export const ImageWrapper: React.FC = () => {

	return (
		<div className="imagewrapper">
			<div className="imagewrapper--title">
				Dilution reference
			</div>
			<div className="imagewrapper--desc">
				For additional details, please read the
			</div>
			<div className="imagewrapper--desc link">
				<Link to={Page.TutorialsPage}>
					consistency tutorial
				</Link>
			</div>
			<div className="imagewrapper--area">
				<img src={"./assets/dilutionreference.png"}/>
			</div>
		</div>
	);
};
