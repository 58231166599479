import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GeneralDataForm } from "./GeneralDataForm";
import { ConsistencyProvider } from "./ConsistencyCalculation/ConsistencyContext";
import { ConsistencyPage } from "./ConsistencyCalculation/ConsistencyPage";
import { Page } from "./Constants";
import { GeneralProvider } from "./GeneralContext";
import { HelpPage } from "./HelpPage";
import { LandingPage } from "./LandingPage";
import { Navigation } from "./Navigation";
import { DilutionPage } from "./DilutionCalculator/DilutionPage";
import { DilutionProvider } from "./DilutionCalculator/DilutionContext";
import { MaterialsPage } from "./MiscPages/MaterialsPage";
import { TutorialsPage } from "./MiscPages/TutorialsPage";
import { UnitConverter } from "./DilutionCalculator/UnitConverter";

/**
 * Main app level component.
 * Handles the view switching and layout.
 */
export const App: React.FC<{}> = (): JSX.Element => {
	return (
		<div className="approot">
			<GeneralProvider>
				<ConsistencyProvider>
					<DilutionProvider>
						<div id="navigation">
							<Navigation />
						</div>
						<div id="mainview">
							<Router>
								<Switch>
									<Route path={Page.GeneralDataPage}>
										<GeneralDataForm />
									</Route>
									<Route path={Page.ConsistencyPage}>
										<ConsistencyPage />
									</Route>
									<Route path={Page.DilutionPage}>
										<DilutionPage />
									</Route>
									<Route path={Page.UnitConverterPage}>
										<UnitConverter />
									</Route>
									<Route path={Page.HelpPage}>
										<HelpPage />
									</Route>
									<Route path={Page.MaterialsPage}>
										<MaterialsPage />
									</Route>
									<Route path={Page.TutorialsPage}>
										<TutorialsPage />
									</Route>
									<Route path={Page.LandingPage}>
										<LandingPage />
									</Route>
								</Switch>
							</Router>
						</div>
						<div className="footer">
							<div className="footer-main">
								<div>
									<a
										href="https://www.valmet.com/content_folder/legal-notice/"
										target="_self"
									>
										Legal notice
									</a>
								</div>
								<div>
									<a
										href="https://www.valmet.com/content_folder/trademarks/"
										target="_self"
									>
										Trademarks
									</a>
								</div>
								<div>
									<a
										href="https://www.valmet.com/content_folder/privacy-notice/"
										target="_self"
									>
										Privacy notice
									</a>
								</div>
								<div>
									<a href="https://www.valmet.com/about-us/contact-us/contact-form/">
										Contact us
									</a>
								</div>
								<div>
									<a
										href="https://www.valmet.com/media/social-media/"
										target="_self"
									>
										Social media
									</a>
								</div>
								<div>
									&copy; {new Date().getFullYear()} Valmet
								</div>
							</div>
						</div>
					</DilutionProvider>
				</ConsistencyProvider>
			</GeneralProvider>
		</div>
	);
};
